import {
  LOAD_DASHBOARD_COUNT,
  LOAD_DASHBOARD_COUNT_FAIL,
  ACTIVE_CONTRACT_GRAPH,
  MONTHLY_INCENTIVE_GRAPH,
  ACCRUAL_PAYMENT_GRAPH_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS,
  PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS,
  PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS,
  INCOMING_CLAIM_SUCCESS,
  CHART_DATA_SUCCESS,
  ACTIVE_CONTRACTS_BY_CONTRACT_GROUP,
  ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP,
  MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_TYPE,
  MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_GROUP,
  MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP,
  INCOMING_CLAIM_DETAIL_SUCCESS,
  OUTGOING_CLAIM_SUCCESS,
  OUTGOING_DYNAMIC_CLAIM_SUCCESS,
  SALES_PAYMENT_GRAPH_SUCCESS,
  ACCRUAL_VARIANCE_SUCCESS,
  ACCRUAL_VARIANCE_CHART_SUCCESS,
  CONTRACT_VARIANCE_SUCCESS,
  SOLD_TO_PARTY_VARIANCE_SUCCESS,
  MATERIAL_NUMBER_VARIANCE_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS,
  PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS,
  GEORGRAPHICAL_INCENTIVE,
  TOP_25,
  REBATE_TRENDS_SECOND,
  VARIANCE_COMBINED_SUCCESS,
  MONTHLY_VARIANCE_COMBINED_SUCCESS,
  POSTING_SUMMARY_COMBINED_SUCCESS,
  INCENTIVE_TREND_SUCCESS,
  ALLOWED_APPS_SUCCESS,
  DASHBOARD_ANALYTICS_NAMES_SUCCESS,
  DASHBOARD_DASHBOARD_NAMES_SUCCESS,
  PAYMENT_DURATION,
  CLAIM_ERROR,
  CLAIM_AGING_CUSTOMER_CHARGEBACK,
  CLAIM_AGING_COUNT_CUSTOMER_CHARGEBACK,
  OUTGOING_CLAIM_DETAIL_SUCCESS,
  FETCH_START,
  FETCH_END,
  CLAIM_VALUE_AND_ERROR_CBS,
  CLAIM_COUNT_CCB_SCB,
  PROM_PLAN_SALES_INCE,
  TIME_TO_MARKET,
  MARKET_SHARE,
  PROMO_BUDGET_REENUE,
  PROMO_ANALY_COST,
  PROMO_COST_PERC,
  PRICING_DASHBOARD_MARGIN_CUSTOMER,
  PRICING_DASHBOARD_MARGIN_MATERIAL,
  DISPUTED_CLAIM_DETAIL_SUCCESS,
  POST_OUT_DATA,
  PRICING_DASHBOARD_COMBINED,
  PRICING_MARGIN_REVENUE,
  PRICING_MARGIN_REVENUE_DASHBOARD,
  TOP_BOTTOM_PERFORMER,
  MARGIN_PRICE__DIFF,
  DASHBOARD_DATA,
  NEW_CUSTOMERS,
  REBATE_OBJECTIVE_SUCCESS,
} from '../../../actions/Initial/Dashboard/ActionTypes';

const initialState = {
  dashboardCountData: [],
  accrualPaymentGraphData: [],
  salesPaymentGraphData: [],
  paymentGraphContractTypeSuccessData: [],
  paymentGraphCalculationSchemaSuccessData: [],
  paymentGraphContractGroupSuccessData: [],
  paymentGraphContractTypeAccrualSuccessData: [],
  paymentGraphCalculationSchemaAccrualSuccessData: [],
  paymentGraphContractGroupAccrualSuccessData: [],
  incomingClaimListData: [],
  contractChartData: [],
  activeContractsByContractGroup: [],
  activeContractsByContractSubGroup: [],
  monthlyActiveContractsByContractType: [],
  monthlyActiveContractsByContractGroup: [],
  monthlyActiveContractsByContractSubGroup: [],
  incomingClaimDetailData: [],
  outgoingClaimListData: [],
  outgoingClaimListDynamicData: [],
  accrualVarianceGraphData: [],
  accrualVarianceChartData: [],
  contractVarianceGraphData: [],
  soldToPartyVarianceGraphData: [],
  materialNumberVarianceGraphData: [],
  dashboardCountDataError: false,
  geographicalIncentive: [],
  top25: [],
  georgraphicalIncentive: [],
  rebateTrendsSecond: {},
  varianceCombinedData: [],
  varianceMonthlyCombinedData: [],
  postingSummaryCombinedData: [],
  incentiveTrendsData: [],
  allowedApps: [],
  dashboardanalyticsname: [],
  dashboardashboardname: [],
  paymentDuration: {},
  claimError: [],
  outgoingClaimDetailData: [],
  loading: false,
  claimValueAndErrorCB: {},
  claimCountCCBSCB: {},
  promPlanningSalesInc: {},
  timeToMarket: [],
  marketShare: [],
  promoAnalyCost: [],
  promoAnalyRevenue: [],
  promoCostPer: [],
  pricingDashboard: [],
  pricingDashboardCustomer: [],
  pricingDashboardMaterial: [],
  disputedClaims: [],
  postOutgoingData: [],
  pricingCombinedData: [],
  pricingRevenueMarginData: [],
  pricingRevenueMarginDashboardData: [],
  topBottomPerformer: [],
  marginPriceDiff: [],
  dashboardData: [],
  newCustomerData: [],
  incentiveData: {},
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      return {
        ...state,
        loading: false,
      };
    case LOAD_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCountData: action.data,
        dashboardCountDataError: false,
      };
    case LOAD_DASHBOARD_COUNT_FAIL:
      return {
        ...state,
        dashboardCountDataError: true,
      };
    //Graph
    case ACTIVE_CONTRACT_GRAPH:
      return {
        ...state,
        activeContractsGraphData: action.data,
      };
    case MONTHLY_INCENTIVE_GRAPH:
      return {
        ...state,
        monthlyIncentiveGraphData: action.data,
      };
    case ACCRUAL_PAYMENT_GRAPH_SUCCESS:
      return {
        ...state,
        accrualPaymentGraphData: action.data,
      };
    case SALES_PAYMENT_GRAPH_SUCCESS:
      return {
        ...state,
        salesPaymentGraphData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS:
      return {
        ...state,
        paymentGraphContractTypeSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS:
      return {
        ...state,
        paymentGraphContractGroupSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS:
      return {
        ...state,
        paymentGraphContractSubGroupSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS:
      return {
        ...state,
        paymentGraphCalculationSchemaSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS:
      return {
        ...state,
        paymentGraphContractTypeAccrualSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS:
      return {
        ...state,
        paymentGraphContractGroupAccrualSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS:
      return {
        ...state,
        paymentGraphContractSubGroupAccrualSuccessData: action.data,
      };
    case PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS:
      return {
        ...state,
        paymentGraphCalculationSchemaAccrualSuccessData: action.data,
      };
    case INCOMING_CLAIM_SUCCESS:
      return {
        ...state,
        incomingClaimListData: action.data,
      };
    case CHART_DATA_SUCCESS:
      return {
        ...state,
        contractChartData: action.data,
      };
    case ACTIVE_CONTRACTS_BY_CONTRACT_GROUP:
      return {
        ...state,
        activeContractsByContractGroup: action.data,
      };
    case ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP:
      return {
        ...state,
        activeContractsByContractSubGroup: action.data,
      };
    case MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_TYPE:
      return {
        ...state,
        monthlyActiveContractsByContractType: action.data,
      };
    case MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_GROUP:
      return {
        ...state,
        monthlyActiveContractsByContractGroup: action.data,
      };
    case MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP:
      return {
        ...state,
        monthlyActiveContractsByContractSubGroup: action.data,
      };
    case INCOMING_CLAIM_DETAIL_SUCCESS:
      return {
        ...state,
        incomingClaimDetailData: action.data,
      };
    case OUTGOING_CLAIM_DETAIL_SUCCESS:
      return {
        ...state,
        outgoingClaimDetailData: action.data,
      };
    case OUTGOING_CLAIM_SUCCESS:
      return {
        ...state,
        outgoingClaimListData: action.data,
      };
    case OUTGOING_DYNAMIC_CLAIM_SUCCESS:
      return {
        ...state,
        outgoingClaimListDynamicData: action.data,
      };
    case ACCRUAL_VARIANCE_SUCCESS:
      return {
        ...state,
        accrualVarianceGraphData: action.data,
      };
    case ACCRUAL_VARIANCE_CHART_SUCCESS:
      return {
        ...state,
        accrualVarianceChartData: action.data,
      };
    case CONTRACT_VARIANCE_SUCCESS:
      return {
        ...state,
        contractVarianceGraphData: action.data,
      };
    case SOLD_TO_PARTY_VARIANCE_SUCCESS:
      return {
        ...state,
        soldToPartyVarianceGraphData: action.data,
      };
    case MATERIAL_NUMBER_VARIANCE_SUCCESS:
      return {
        ...state,
        materialNumberVarianceGraphData: action.data,
      };
    case GEORGRAPHICAL_INCENTIVE:
      return {
        ...state,
        georgraphicalIncentive: action.data,
      };
    case TOP_25:
      return {
        ...state,
        top25: action.data,
      };
    case REBATE_TRENDS_SECOND:
      return {
        ...state,
        rebateTrendsSecond: action.data,
      };
    case VARIANCE_COMBINED_SUCCESS:
      return {
        ...state,
        varianceCombinedData: action.data,
      };
    case MONTHLY_VARIANCE_COMBINED_SUCCESS:
      return {
        ...state,
        varianceMonthlyCombinedData: action.data,
      };
    case POSTING_SUMMARY_COMBINED_SUCCESS:
      return {
        ...state,
        postingSummaryCombinedData: action.data,
      };
    case INCENTIVE_TREND_SUCCESS:
      return {
        ...state,
        incentiveTrendsData: action.data,
      };
    case ALLOWED_APPS_SUCCESS:
      return {
        ...state,
        allowedApps: action.data,
      };
    case DASHBOARD_ANALYTICS_NAMES_SUCCESS:
      return {
        ...state,
        dashboardanalyticsname: action.data,
      };
    case DASHBOARD_DASHBOARD_NAMES_SUCCESS:
      return {
        ...state,
        dashboardashboardname: action.data,
      };
    case PAYMENT_DURATION:
      return {
        ...state,
        paymentDuration: action.data,
      };
    case CLAIM_ERROR:
      return {
        ...state,
        claimError: action.data,
      };
    case CLAIM_AGING_CUSTOMER_CHARGEBACK:
      return {
        ...state,
        claimAgingCustomerChargeback: action.data,
      };
    case CLAIM_AGING_COUNT_CUSTOMER_CHARGEBACK:
      return {
        ...state,
        claimAgingCountCustomerChargeback: action.data,
      };
    case CLAIM_VALUE_AND_ERROR_CBS:
      return {
        ...state,
        claimValueAndErrorCB: action.data,
      };
    case CLAIM_COUNT_CCB_SCB:
      return {
        ...state,
        claimCountCCBSCB: action.data,
      };
    case PROM_PLAN_SALES_INCE:
      return {
        ...state,
        promPlanningSalesInc: action.data,
      };
    case TIME_TO_MARKET:
      return {
        ...state,
        timeToMarket: action.data,
      };
    case MARKET_SHARE:
      return {
        ...state,
        marketShare: action.data,
      };
    //promo plan
    case PROMO_ANALY_COST:
      return {
        ...state,
        promoAnalyCost: action.data,
      };
    case PROMO_BUDGET_REENUE:
      return {
        ...state,
        promoAnalyRevenue: action.data,
      };
    case PROMO_COST_PERC:
      return {
        ...state,
        promoCostPer: action.data,
      };
    case PRICING_DASHBOARD_MARGIN_CUSTOMER:
      return {
        ...state,
        pricingDashboardCustomer: action.data,
      };
    case PRICING_DASHBOARD_MARGIN_MATERIAL:
      return {
        ...state,
        pricingDashboardMaterial: action.data,
      };
    case DISPUTED_CLAIM_DETAIL_SUCCESS:
      return {
        ...state,
        disputedClaims: action.data,
      };
    case POST_OUT_DATA:
      return {
        ...state,
        postOutgoingData: action.data,
      };
    case PRICING_DASHBOARD_COMBINED:
      return {
        ...state,
        pricingCombinedData: action.data,
      };
    case PRICING_MARGIN_REVENUE:
      return {
        ...state,
        pricingRevenueMarginData: action.data,
      };
    case PRICING_MARGIN_REVENUE_DASHBOARD:
      return {
        ...state,
        pricingRevenueMarginDashboardData: action.data,
      };
    case TOP_BOTTOM_PERFORMER:
      return {
        ...state,
        topBottomPerformer: action.data,
      };
    case MARGIN_PRICE__DIFF:
      return {
        ...state,
        topBottomPerformer: action.data,
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.data,
      };
    case NEW_CUSTOMERS:
      return {
        ...state,
        newCustomerData: action.data,
      };
    case REBATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        incentiveData: action.data,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
