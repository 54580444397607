export const LOAD_DASHBOARD_COUNT = 'LOAD_DASHBOARD_COUNT';
export const LOAD_DASHBOARD_COUNT_FAIL = 'LOAD_DASHBOARD_COUNT_FAIL';
export const ACTIVE_CONTRACT_GRAPH = 'ACTIVE_CONTRACT_GRAPH';
export const MONTHLY_INCENTIVE_GRAPH = 'MONTHLY_INCENTIVE_GRAPH';
export const ACCRUAL_PAYMENT_GRAPH_SUCCESS = 'ACCRUAL_PAYMENT_GRAPH_SUCCESS';
export const SALES_PAYMENT_GRAPH_SUCCESS = 'SALES_PAYMENT_GRAPH_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_TYPE_SUCCESS';
export const PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS =
  'PAYMENT_GRAPH_CALCULATION_SCHEMA_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_GROUP_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_TYPE_ACCRUAL_SUCCESS';
export const PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS =
  'PAYMENT_GRAPH_CALCULATION_SCHEMA_ACCRUAL_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_GROUP_ACCRUAL_SUCCESS';
export const INCOMING_CLAIM_SUCCESS = 'INCOMING_CLAIM_SUCCESS';
export const INCOMING_CLAIM_DETAIL_SUCCESS = 'INCOMING_CLAIM_DETAIL_SUCCESS';
export const OUTGOING_CLAIM_SUCCESS = 'OUTGOING_CLAIM_SUCCESS';
export const OUTGOING_DYNAMIC_CLAIM_SUCCESS = ' OUTGOING_DYNAMIC_CLAIM_SUCCESS';
export const ACCRUAL_VARIANCE_SUCCESS = 'ACCRUAL_VARIANCE_SUCCESS';
export const ACCRUAL_VARIANCE_CHART_SUCCESS = 'ACCRUAL_VARIANCE_CHART_SUCCESS';
export const CONTRACT_VARIANCE_SUCCESS = 'CONTRACT_VARIANCE_SUCCESS';
export const SOLD_TO_PARTY_VARIANCE_SUCCESS = 'SOLD_TO_PARTY_VARIANCE_SUCCESS';
export const MATERIAL_NUMBER_VARIANCE_SUCCESS =
  'MATERIAL_NUMBER_VARIANCE_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_SUB_GROUP_SUCCESS';
export const PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS =
  'PAYMENT_GRAPH_CONTRACT_SUB_GROUP_ACCRUAL_SUCCESS';
export const GEORGRAPHICAL_INCENTIVE = 'GEORGRAPHICAL_INCENTIVE';
export const TOP_25 = 'TOP_25';
export const REBATE_TRENDS_SECOND = 'REBATE_TRENDS_SECOND';
export const VARIANCE_COMBINED_SUCCESS = 'VARIANCE_COMBINED_SUCCESS';
export const MONTHLY_VARIANCE_COMBINED_SUCCESS =
  'MONTHLY_VARIANCE_COMBINED_SUCCESS';
export const POSTING_SUMMARY_COMBINED_SUCCESS =
  'POSTING_SUMMARY_COMBINED_SUCCESS';
export const INCENTIVE_TREND_SUCCESS = 'INCENTIVE_TREND_SUCCESS';
export const ALLOWED_APPS_SUCCESS = 'ALLOWED_APPS_SUCCESS';
export const DASHBOARD_ANALYTICS_NAMES_SUCCESS =
  'DASHBOARD_ANALYTICS_NAMES_SUCCESS';
export const DASHBOARD_DASHBOARD_NAMES_SUCCESS =
  'DASHBOARD_DASHBOARD_NAMES_SUCCESS';
export const PAYMENT_DURATION = 'PAYMENT_DURATION';
export const CLAIM_ERROR = 'CLAIM_ERROR';
export const CLAIM_AGING_CUSTOMER_CHARGEBACK =
  'CLAIM_AGING_CUSTOMER_CHARGEBACK';
export const CLAIM_AGING_COUNT_CUSTOMER_CHARGEBACK = 'CLAIM_AGING_COUNT_CUSTOMER_CHARGEBACK';
export const OUTGOING_CLAIM_DETAIL_SUCCESS = 'OUTGOING_CLAIM_DETAIL_SUCCESS';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const CLAIM_VALUE_AND_ERROR_CBS = 'CLAIM_VALUE_AND_ERROR_CBS';
export const CLAIM_COUNT_CCB_SCB = 'CLAIM_COUNT_CCB_SCB';
export const PROM_PLAN_SALES_INCE = 'PROM_PLAN_SALES_INCE';
export const TIME_TO_MARKET = 'TIME_TO_MARKET';
export const MARKET_SHARE = 'MARKET_SHARE';
export const PROMO_BUDGET_REENUE = 'PROMO_BUDGET_REENUE';
export const PROMO_ANALY_COST = 'PROMO_ANALY_COST';
export const PROMO_COST_PERC = 'PROMO_COST_PERC';
export const PRICING_DASHBOARD_MARGIN_CUSTOMER =
  'PRICING_DASHBOARD_MARGIN_CUSTOMER';
export const PRICING_DASHBOARD_MARGIN_MATERIAL =
  'PRICING_DASHBOARD_MARGIN_MATERIAL';
export const DISPUTED_CLAIM_DETAIL_SUCCESS = 'DISPUTED_CLAIM_DETAIL_SUCCESS';
export const POST_OUT_DATA = 'POST_OUT_DATA';
export const PRICING_DASHBOARD_COMBINED = 'PRICING_DASHBOARD_COMBINED';
export const PRICING_MARGIN_REVENUE = 'PRICING_MARGIN_REVENUE';
export const PRICING_MARGIN_REVENUE_DASHBOARD =
  'PRICING_MARGIN_REVENUE_DASHBOARD';
export const TOP_BOTTOM_PERFORMER = 'TOP_BOTTOM_PERFORMER';
export const MARGIN_PRICE__DIFF = 'MARGIN_PRICE__DIFF';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const NEW_CUSTOMERS = 'NEW_CUSTOMERS';
export const REBATE_OBJECTIVE_SUCCESS = 'REBATE_OBJECTIVE_SUCCESS';
export const CHART_DATA_SUCCESS = 'CHART_DATA_SUCCESS';
export const ACTIVE_CONTRACTS_BY_CONTRACT_GROUP =
  'ACTIVE_CONTRACTS_BY_CONTRACT_GROUP';
export const ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP =
  'ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP';
export const MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_TYPE =
  'MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_TYPE';
export const MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_GROUP =
  'MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_GROUP';
export const MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP =
  'MONTHLY_ACTIVE_CONTRACTS_BY_CONTRACT_SUBGROUP';
