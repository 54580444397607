import {
  ATTRIBUTE_LIST_SUCCESS,
  ATTRIBUTE_LIST_FAIL,
  EDIT_ATTRIBUTES_SUCCESS,
  EDIT_ATTRIBUTES_FAIL,
  FETCH_START,
  FETCH_END,
  ATTRIBUTE_NAME_SUCCESS,
  DROPDOWN_START,
  DROPDOWN_END,
  DROPDOWN_DATA_SUCCESS,
  DROPDOWN_DATA_VALIDATION_SUCCESS,
  DROPDOWN_MASTER_DATA_SUCCESS,
  CREATE_ATTRIBUTE_HIERARCHY_SUCCESS,
  ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS,
  ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS,
  DELETE_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
  SAVE_EDIT_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
  UPLOAD_ATTRIBUTE_MANAGEMENT_FILE_SUCCESS,
  CREATE_USER_DEFINED_ATTRIBUTE_SUCCESS,
  CREATE_USER_DEFINED_ATTRIBUTE_FAIL,
  UPDATE_USER_DEFINED_ATTRIBUTE_SUCCESS,
  UPDATE_USER_DEFINED_ATTRIBUTE_FAIL,
  GET_ATTRIBUTE_VALUES_SUCCESS,
  GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS,
  CLEAR_ATTRIBUTE_TYPE_BY_ID,
  UPDATE_ATTRIBUTE_VALUE_SUCCESS,
  UPDATE_ATTRIBUTE_VALUE_FAIL,
  GET_ATTRIBUTE_DROPDOWN_VALUES
} from './ActionTypes';
import axios from '../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
import { history } from '../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const saveEditAttributeData = (data) => {
  return {
    type: EDIT_ATTRIBUTES_SUCCESS,
    data,
  };
};

export const getAttributeListDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_LIST_SUCCESS,
    data,
  };
};

export const getAttributeListDataFailed = (error) => {
  return {
    type: ATTRIBUTE_LIST_FAIL,
    error,
  };
};
export const getAttributeNameDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_NAME_SUCCESS,
    data,
  };
};
export const getDropdownValidationSuccess = (data) => {
  return {
    type: DROPDOWN_DATA_VALIDATION_SUCCESS,
    data,
  };
};
export const getDropdownDataSuccess = (data) => {
  return {
    type: DROPDOWN_DATA_SUCCESS,
    data,
  };
};
export const getDropdownDataMasterDataSuccess = (data) => {
  return {
    type: DROPDOWN_MASTER_DATA_SUCCESS,
    data,
  };
};
export const fetchDropdownStart = () => {
  return {
    type: DROPDOWN_START,
  };
};

export const fetchDropdownEnd = () => {
  return {
    type: DROPDOWN_END,
  };
};

export const getAttributeListData = (data, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeListDataApi(data, page)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getAttributeListDataFailed(error));
        //toast.error(ServerError);
      });
  };
};

const getAttributeListDataApi = (data, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'hierarchy') {
    result = axios.get('eligibility-attrib-multiple', { headers: headers });
    return result;
  } else {
    result = axios.get('eligibility-attrib', { headers: headers });
    return result;
  }
};

export const createAttributeData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributeDataApi({
      attribute_name: data.attribute_name,
      attribute_value: data.attribute_value,
      attribute_desc: data.attribute_desc,
      is_standard: false, // Always set to false for user-defined attributes
    })
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          history.push('/attributes');
          toast.success('Successfully created');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const createAttributeDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('eligibility-attrib', data, { headers: headers });
  return result;
};

export const updateAttributeData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateAttributeDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          toast.success('Successfully updated');
          history.push('/attributes');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateAttributeDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('eligibility-attrib/' + id, data, { headers: headers });
  return result;
};

export const deleteAttributeData = (id, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteAttributeDataApi(id, page)
      .then((response) => {
        dispatch(fetchEnd());
        if (page === 'hierarchy') {
          dispatch(getAttributeListData(null, 'hierarchy'));
          toast.success('Successfully deleted');
        } else {
          dispatch(getAttributeListData());
          toast.success('Successfully deleted');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteAttributeDataApi = (id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'hierarchy') {
    result = axios.delete('eligibility-hierarchy-attrib/' + id, {
      headers: headers,
    });
    return result;
  } else {
    result = axios.delete('eligibility-attrib/' + id, { headers: headers });
    return result;
  }
};

// //upload File
export const uploadAttributeFile = (file, page) => {
  return (dispatch) => {
    uploadAttributeFileApi(file, page)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          if (page === 'hierarchy') {
            dispatch(getAttributeListData(null, 'hierarchy'));
            toast.success('File has been successfully uploaded.');
          } else {
            dispatch(getAttributeListData());
            toast.success('File has been successfully uploaded.');
          }
        } else {
          toast.error(ServerError);
        }
      })
      .catch((error) => {
        // console.log(error.response.data.errors);
        const errorMessages = Object.values(error.response.data.errors);
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage.trim());
        });
        // const errorMessages = error.response.data.errors.split(';');
        // errorMessages.forEach((errorMessage) => {
        //   toast.error(errorMessage.trim());
        // });
      });
  };
};

const uploadAttributeFileApi = (file, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (page === 'hierarchy') {
    result = axios.post('upload-hierarchy-attrib', file, { headers: headers });
    return result;
  } else {
    result = axios.post('eligibility-attrib-upload', file, {
      headers: headers,
    });
    return result;
  }
};

export const getAttributeNameList = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeNameApi()
      .then((response) => {
        dispatch(getAttributeNameDataSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getAttributeNameApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/attribute-names', { headers: headers });
  return result;
};

export const uploadEDIFile = (file) => {
  return (dispatch) => {
    uploadEDIFileAPI(file)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadEDIFileAPI = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('incoming-claim-edi', file, { headers: headers });
  return result;
};
//searched dropdown
export const getDropdownList = (table, data, type) => {
  return (dispatch) => {
    dispatch(fetchDropdownStart());
    getDropdownApi(table, data)
      .then((response) => {
        if (type === 'Master Data') {
          dispatch(getDropdownDataMasterDataSuccess(response.data.response));
        } else {
          dispatch(getDropdownDataSuccess(response.data.response));
        }
        dispatch(fetchDropdownEnd());
      })
      .catch((error) => {
        dispatch(fetchDropdownEnd());
      });
  };
};

const getDropdownApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('dropdown-search/' + table, data, { headers: headers });
  return result;
};

// Search DropDown Validation

export const getDropdownValidation = (table, data) => {
  return (dispatch) => {
    // dispatch(fetchDropdownStart());
    getDropdownValidationApi(table, data)
      .then((response) => {
        sessionStorage.setItem(
          'dropdownvalidation',
          JSON.stringify(response.data.response),
        );
        dispatch(getDropdownValidationSuccess(response.data.response));
        // dispatch(fetchDropdownEnd());
      })
      .catch((error) => {
        // dispatch(fetchDropdownEnd());
      });
  };
};

const getDropdownValidationApi = (table, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('dropdown-multi-search/' + table, data, {
    headers: headers,
  });
  return result;
};

//Attributes Hierarchy

// Add Attributes Hierarchy

export const createAttributeHierarchyData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createAttributeHierarchyDataAPi(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(fetchEnd());
          dispatch(createAttributeHierarchyDataSuccess(response.data.response));
          history.push('/attributes-hierarchy');
        } else {
          toast.warning('No records found.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createAttributeHierarchyDataAPi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (id) {
    var result = axios.put('eligibility-hierarchy-attrib/' + id, data, {
      headers: headers,
    });
    return result;
  } else {
    var result = axios.post('eligibility-attrib-multiple', data, {
      headers: headers,
    });
    return result;
  }
};
export const createAttributeHierarchyDataSuccess = (data) => {
  return {
    type: CREATE_ATTRIBUTE_HIERARCHY_SUCCESS,
    data,
  };
};

// Get Single Attributes Hierarchy

export const getAttributeListSingleData = (id) => {
  return (dispatch) => {
    getAttributeListSingleDataApi(id)
      .then((response) => {
        dispatch(getAttributeListSingleDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getAttributeListSingleDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('eligibility-attrib-multiple/' + id, { headers: headers });
  return result;
};

export const getAttributeListSingleDataSuccess = (data) => {
  return {
    type: ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS,
    data,
  };
};
// new
export const getAttributeManagementListData = (data, page = 1, limit = 10, attributeType = null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeManagementListDataApi(data, page, limit, attributeType)
      .then((response) => {
        console.log('Raw API Response:', response);
        if (response.data && response.data.response) {
          const { records, total_record } = response.data.response;
          console.log(`Received ${records?.length || 0} records with attribute_type filter: ${attributeType}`);
          if (records && Array.isArray(records)) {
            // Transform the records to match the expected format
            const transformedRecords = records.map(record => ({
              ...record.attribute_type,
              id: record.attribute_type.ID, // Ensure lowercase 'id' exists for DataGrid
              values: record.values || [] // Include values array
            }));

            // Get the total count from the response
            const totalCount = total_record || records.length;

            dispatch(getAttributeManagementListDataSuccess(transformedRecords, totalCount));
          } else {
            console.warn('No records found in the API response');
            dispatch(getAttributeManagementListDataSuccess([], 0));
          }
        } else {
          console.warn('Invalid API response format:', response.data);
          dispatch(getAttributeManagementListDataSuccess([], 0));
        }
        dispatch(fetchEnd());
      })
      .catch((error) => {
        console.error('API Error:', error);
        dispatch(fetchEnd());
        dispatch(getAttributeManagementListDataSuccess([], 0));
      });
  };
};

const getAttributeManagementListDataApi = (data, page = 1, limit = 10, attributeType = null) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  let url = `/mdm-attribute-types?page=${page}&limit=${limit}&with_values=true`;
  // Add attribute_type filter if provided
  if (attributeType) {
    url += `&attribute_type=${attributeType}`;
  }
  console.log('Fetching attributes with URL:', url);
  result = axios.get(url, { headers: headers });
  return result;
};

export const getAttributeManagementListDataSuccess = (data, totalCount) => {
  return {
    type: ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS,
    data,
    totalCount
  };
};

// export const deleteAttributeManagementData = (id) => {
//   return (dispatch) => {
//     dispatch(fetchStart());
//     deleteAttributeManagementDataApi(id)
//       .then((response) => {
//         dispatch(fetchEnd());
//         if (response.status === 200) {
//           toast.success('Successfully deleted');
//         } else {
//           //toast.error(ServerError);
//         }
//       })
//       .catch((error) => {
//         dispatch(fetchEnd());
//         //toast.error(ServerError);
//       });
//   };
// };

export const deleteAttributeManagementData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteAttributeManagementDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          toast.success('Successfully deleted');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteAttributeManagementDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('/mdm-attribute-type/' + id, { headers: headers });
  return result;
};

export const deleteAttributeManagementDataSuccess = (data) => {
  return {
    type: DELETE_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
    data,
  };
};

export const saveEditAttributeManagementData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    saveEditAttributeManagementDataApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 200) {
          toast.success('Successfully saved');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const saveEditAttributeManagementDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('mdm-material-master', data, { headers: headers });
  return result;
};

export const saveEditAttributeManagementDataSuccess = (data) => {
  return {
    type: SAVE_EDIT_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
    data,
  };
};

export const uploadAttributeManagementFile = (file) => {
  return (dispatch) => {
    uploadAttributeManagementFileApi(file)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          toast.success('File has been successfully uploaded.');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const uploadAttributeManagementFileApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('mdm-material-master-upload', file, { headers: headers });
  return result;
};

// Action creators for user-defined attributes
export const createUserDefinedAttributeSuccess = (data) => {
  return {
    type: CREATE_USER_DEFINED_ATTRIBUTE_SUCCESS,
    data,
  };
};

export const createUserDefinedAttributeFail = (error) => {
  return {
    type: CREATE_USER_DEFINED_ATTRIBUTE_FAIL,
    error,
  };
};

// Redux thunk action to create user-defined attribute with values
export const createUserDefinedAttribute = (attributeData, attributeValues = []) => {
  return (dispatch) => {
    dispatch(fetchStart());

    // Create payload with the new structure
    const payload = {
      attribute_type: {
        attribute_type: attributeData.attribute_type,
        attribute: attributeData.attribute,
        attribute_description: attributeData.attribute_description,
        attribute_label: attributeData.attribute_label,
        data_type: attributeData.data_type,
        values_display_only: attributeData.values_display_only,
        foreign_table: attributeData.foreign_table || '',
        foreign_field: attributeData.foreign_field || '',
        description_table: attributeData.description_table || '',
        description_field: attributeData.description_field || ''
      },
      values: attributeValues
    };

    return axios({
      method: 'post',
      url: '/mdm-attribute-type/batch',
      data: payload,
      headers: {
        'Authorization': secureLocalStorage.getItem('aTk'),
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(createUserDefinedAttributeSuccess(response.data));
        toast.success('Attribute created successfully');
        return response.data;
      })
      .catch(error => {
        console.error('Error creating attribute:', error);
        dispatch(fetchEnd());
        dispatch(createUserDefinedAttributeFail(error));
        toast.error('Error creating attribute: ' + (error.response?.data?.message || error.message));
        throw error;
      });
  };
};

// Get attribute values for a specific attribute type
export const getAttributeValues = (attributeTypeId) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeValuesApi(attributeTypeId)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeValuesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        console.error('Error fetching attribute values:', error);
      });
  };
};

const getAttributeValuesApi = (attributeTypeId) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.get(`/mdm-attribute-values?attribute_type_id=${attributeTypeId}&with_values=true`, { headers: headers });
};

export const getAttributeValuesSuccess = (data) => {
  return {
    type: GET_ATTRIBUTE_VALUES_SUCCESS,
    data,
  };
};

// Get a specific attribute type by ID
export const getAttributeTypeById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeTypeByIdApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeTypeByIdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        console.error('Error fetching attribute type:', error);
      });
  };
};

const getAttributeTypeByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.get(`/mdm-attribute-type/${id}?with_values=true`, { headers: headers });
};

export const getAttributeTypeByIdSuccess = (data) => {
  return {
    type: GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS,
    data,
  };
};

// Update User Defined Attribute
export const updateUserDefinedAttributeStart = () => ({
  type: 'UPDATE_USER_DEFINED_ATTRIBUTE_START',
});

export const updateUserDefinedAttributeSuccess = (data) => ({
  type: UPDATE_USER_DEFINED_ATTRIBUTE_SUCCESS,
  data,
});

export const updateUserDefinedAttributeFail = (error) => ({
  type: UPDATE_USER_DEFINED_ATTRIBUTE_FAIL,
  error,
});

export const updateUserDefinedAttribute = (attributeId, attributeTypeData) => {
  return (dispatch) => {
    dispatch(fetchStart());
    dispatch(updateUserDefinedAttributeStart());

    // Return a promise so we can handle success/failure in the component
    return new Promise((resolve, reject) => {
      // Update attribute type and values in a single call
      axios({
        method: 'put',
        url: `/mdm-attribute-type/${attributeId}`,
        data: attributeTypeData, // Already contains attribute_type and values
        headers: {
          'Authorization': secureLocalStorage.getItem('aTk'),
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log('Attribute updated successfully:', response.data);
          dispatch(fetchEnd());
          dispatch(updateUserDefinedAttributeSuccess(response.data));
          toast.success('Attribute updated successfully');
          resolve(response.data);
        })
        .catch(error => {
          console.error('Error updating attribute:', error);
          dispatch(fetchEnd());
          dispatch(updateUserDefinedAttributeFail(error));
          toast.error('Error updating attribute: ' + (error.response?.data?.message || error.message));
          reject(error);
        });
    });
  };
};

// Added updateAttributeValue action creators to handle updating an attribute value in the attribute value table
// using the API endpoint /mdm-attribute-value/{id}.
// Expected payload example:
// {
//   "attribute_type_id": 1,
//   "value": "Updated HR",
//   "description": "Updated Human Resources"
// }
export const updateAttributeValueSuccess = (data) => {
  return {
    type: UPDATE_ATTRIBUTE_VALUE_SUCCESS,
    data,
  };
};

export const updateAttributeValueFail = (error) => {
  return {
    type: UPDATE_ATTRIBUTE_VALUE_FAIL,
    error,
  };
};

export const updateAttributeValue = (attributeValueId, valueData) => {
  return async (dispatch) => {
    try {
      const headers = {
        Authorization: secureLocalStorage.getItem('aTk'),
        'Content-Type': 'application/json',
      };
      const result = await axios.put(`/mdm-attribute-value/${attributeValueId}`, valueData, { headers });
      dispatch(updateAttributeValueSuccess(result.data));
      return Promise.resolve(result.data);
    } catch (error) {
      dispatch(updateAttributeValueFail(error.response?.data || { message: error.message }));
      return Promise.reject(error);
    }
  };
};

// Add a recursive function to update multiple attribute values
export const updateMultipleAttributeValues = (changedValues, index = 0) => {
  return async (dispatch) => {
    // Base case: all items processed
    if (index >= changedValues.length) {
      return Promise.resolve();
    }

    const current = changedValues[index];
    const headers = {
      Authorization: secureLocalStorage.getItem('aTk'),
      'Content-Type': 'application/json',
    };

    try {
      // Call the API to update this attribute value
      const result = await axios.put(
        `/mdm-attribute-value/${current.id}`,
        {
          attribute_type_id: current.attribute_type_id,
          value: current.value,
          description: current.description,
        },
        { headers }
      );

      dispatch({
        type: UPDATE_ATTRIBUTE_VALUE_SUCCESS,
        data: result.data,
      });

      // Recursively process the next item
      return dispatch(updateMultipleAttributeValues(changedValues, index + 1));
    } catch (error) {
      dispatch({
        type: UPDATE_ATTRIBUTE_VALUE_FAIL,
        error: error.response?.data || { message: error.message },
      });
      return Promise.reject(error);
    }
  };
};

export const clearAttributeTypeById = (id) => {
  return {
    type: CLEAR_ATTRIBUTE_TYPE_BY_ID,
    id,
  };
};

// get Attributes dropdown values
export const getAttributeDropdownValues = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAttributeDropdownValuesApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getAttributeDropdownValuesSuccess({ [data.attribute_name]: response.data.response }));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        console.error('Error fetching attribute type:', error);
      });
  };
};

const getAttributeDropdownValuesApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  return axios.post('/mdm/attribute-values', data, { headers: headers });
};

export const getAttributeDropdownValuesSuccess = (data) => {
  return {
    type: GET_ATTRIBUTE_DROPDOWN_VALUES,
    data,
  };
};