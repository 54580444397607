export const FINANCIAL_REPORTS_SUCCESS = 'FINANCIAL_REPORTS_SUCCESS';
export const FINANCIAL_REPORTS_COMPLETE_SUCCESS =
  'FINANCIAL_REPORTS_COMPLETE_SUCCESS';
export const FINANCIAL_SUMMARY_REPORTS_COMPLETE_SUCCESS =
  'FINANCIAL_SUMMARY_REPORTS_COMPLETE_SUCCESS';
export const FINANCIAL_REPORTS_FAILED = 'FINANCIAL_REPORTS_FAILED';
export const CALCULATION_REPORTS_COMPLETE_SUCCESS =
  'CALCULATION_REPORTS_COMPLETE_SUCCESS';
export const TARGET_REPORTS_COMPLETE_SUCCESS =
  'TARGET_REPORTS_COMPLETE_SUCCESS';
export const CONTRACT_CHANGE_REPORTS_COMPLETE_SUCCESS =
  'CONTRACT_CHANGE_REPORTS_COMPLETE_SUCCESS';
export const FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS =
  'FINACIAL_POSTING_APPROVAL_DATA_COMPLETE_SUCCESS';
export const FINANCIAL_SUMMARY_COMPLETE_SUCCESS =
  'FINANCIAL_SUMMARY_COMPLETE_SUCCESS';
export const CALCULATION_REPORTS_SUCCESS = 'CALCULATION_REPORTS_SUCCESS';
export const FINANCIAL_SUMMARY_REPORTS_COMPLETE_FAILED =
  'FINANCIAL_SUMMARY_REPORTS_COMPLETE_FAILED';
export const TARGET_REPORTS_SUCCESS = 'TARGET_REPORTS_SUCCESS';
export const RATIO_KEY_FIGURES_REPORTS_SUCCESS =
  'RATIO_KEY_FIGURES_REPORTS_SUCCESS';
export const INCENTIVE_RATIO_TREND_REPORTS_SUCCESS = 'INCENTIVE_RATIO_TREND_REPORTS_SUCCESS';
export const TOP_N_ATTRIBUTES_REPORTS_SUCCESS =
  'TOP_N_ATTRIBUTES_REPORTS_SUCCESS';
export const DUPLICATE_PAYMENT_REPORTS_SUCCESS =
  'DUPLICATE_PAYMENT_REPORTS_SUCCESS';
export const ANAMOLY_REPORTS_SUCCESS = 'ANAMOLY_REPORTS_SUCCESS';
export const APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS =
  'APPROVAL_POSTING_HISTORY_REPORTS_SUCCESS';
export const CONTRACT_SEARCH_BY_CUSTOMER = 'CONTRACT_SEARCH_BY_CUSTOMER';
export const CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS =
  'CALCULATION_FORMULA_VALUES_REPORTS_SUCCESS';
export const CONTRACT_CHANGE_REPORTS_SUCCESS =
  'CONTRACT_CHANGE_REPORTS_SUCCESS';
export const FINANCIAL_SUMMARY_SUCCESS = 'FINANCIAL_SUMMARY_SUCCESS';
export const CONFIG_CHANGE_HISTORY_SUCCESS = 'CONFIG_CHANGE_HISTORY_SUCCESS';
export const CALCULATION_REPORTS_FAILED = 'CALCULATION_REPORTS_FAILED';
export const TARGET_REPORTS_FAILED = 'TARGET_REPORTS_FAILED';
export const CONTRACT_CHANGE_REPORTS_FAILED = 'CONTRACT_CHANGE_REPORTS_FAILED';
export const FINANCIAL_SUMMARY_FAILED = 'FINANCIAL_SUMMARY_FAILED';
export const INVOICE_REPORTS_SUCCESS = 'INVOICE_REPORTS_SUCCESS';
export const INVOICE_REPORTS_COMPLETE_SUCCESS =
  'INVOICE_REPORTS_COMPLETE_SUCCESS';
export const INVOICE_REPORTS_FAILED = 'INVOICE_REPORTS_FAILED';
export const CONTRACT_REPORTS_SUCCESS = 'CONTRACT_REPORTS_SUCCESS';
export const CONTRACT_REPORTS_COMPLETE_SUCCESS =
  'CONTRACT_REPORTS_COMPLETE_SUCCESS';
export const CONTRACT_REPORTS_FAILED = 'CONTRACT_REPORTS_FAILED';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const CONTRACT_REPORTS_OVERLAP_SUCCESS =
  'CONTRACT_REPORTS_OVERLAP_SUCCESS';
export const DYNAMIC_QUERY_FIELDS_SUCCESS = 'DYNAMIC_QUERY_FIELDS_SUCCESS';
export const DYNAMIC_QUERY_FIELDS_SUCCESS_NEW =
  'DYNAMIC_QUERY_FIELDS_SUCCESS_NEW';
export const QUERY_LIST_SUCCESS = 'QUERY_LIST_SUCCESS';
export const QUERY_RESULTS_FINAL_SUCCESS = 'QUERY_RESULTS_FINAL_SUCCESS';
export const DYNAMIC_QUERY_DETAILS_SUCCESS = 'DYNAMIC_QUERY_DETAILS_SUCCESS';
export const DYNAMIC_QUERY_FIELDS_SALES_DATA_SUCCESS =
  'DYNAMIC_QUERY_FIELDS_SALES_DATA_SUCCESS';
export const CLAIMS_FORMAT_FIELDS_SUCCESS = 'CLAIMS_FORMAT_FIELDS_SUCCESS';
export const INCOMING_CLAIM_DETAILS_SUCCESS = 'INCOMING_CLAIM_DETAILS_SUCCESS';
export const CALCULATION_SIMULATION_FIELDS_SUCCESS =
  'CALCULATION_SIMULATION_FIELDS_SUCCESS';
export const QUERY_LIST_ADMIN_SUCCESS = 'QUERY_LIST_ADMIN_SUCCESS';
export const QUERY_TYPES_SUCCESS = 'QUERY_TYPES_SUCCESS';
export const PURCHASE_DATA_FIELDS_SUCCESS = 'PURCHASE_DATA_FIELDS_SUCCESS';
export const LOAD_TARGET_DATA = 'LOAD_TARGET_DATA';
export const COLL_PORTAL_CUST_SUPP = 'COLL_PORTAL_CUST_SUPP';
export const OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS =
  'OUTGOING_CLAIMS_FORMAT_FIELDS_SUCCESS';
export const OUTGOING_CLAIM_DETAILS_SUCCESS = 'OUTGOING_CLAIM_DETAILS_SUCCESS';
export const GET_ALL_ORG_USERS = 'GET_ALL_ORG_USERS';
export const TABLE_DETERMINATION_SUCCESS = 'TABLE_DETERMINATION_SUCCESS';
export const PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS =
  'PREDEFINED_QUERY_RESULTS_FINAL_SUCCESS';
export const PREDEFINED_QUERY_DETAILS_SUCCESS =
  'PREDEFINED_QUERY_DETAILS_SUCCESS';
export const DYNAMIC_QUERY_FILTER = 'DYNAMIC_QUERY_FILTER';
export const DYNAMIC_QUERY_FILTER_BY_ID = 'DYNAMIC_QUERY_FILTER_BY_ID';
export const CONTRACT_HISTORY_APPROVAL_SUCCESS =
  'CONTRACT_HISTORY_APPROVAL_SUCCESS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const GET_MATERIAL_DESIGN = 'GET_MATERIAL_DESIGN';
export const ADD_MATERIAL_DESIGN = 'ADD_MATERIAL_DESIGN';
export const VIEW_MATERIAL_DESIGN_SUCCESS = 'VIEW_MATERIAL_DESIGN_SUCCESS';