export const CUSTOMER_MASTER_SUCCESS = 'CUSTOMER_MASTER_SUCCESS';
export const CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS =
  'CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS';
export const CUSTOMER_MASTER_XREF_SUCCESS = 'CUSTOMER_MASTER_XREF_SUCCESS';
export const CUSTOMER_MASTER_XREF_DETAIL_SUCCESS =
  'CUSTOMER_MASTER_XREF_DETAIL_SUCCESS';
export const CUSTOMER_MASTER_XREF_FORMAT_SUCCESS =
  'CUSTOMER_MASTER_XREF_FORMAT_SUCCESS';
export const CUSTOMER_MASTER_XREF_BY_ID_SUCCESS =
  'CUSTOMER_MASTER_XREF_BY_ID_SUCCESS';
export const CUSTOMER_MASTER_XREF_FIELDS_SUCCESS =
  'CUSTOMER_MASTER_XREF_FIELDS_SUCCESS';
export const CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS =
  'CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS';
export const MATERIAL_MASTER_SUCCESS = 'MATERIAL_MASTER_SUCCESS';
export const MATERIAL_MASTER_VIEW_DETAIL_SUCCESS =
  'MATERIAL_MASTER_VIEW_DETAIL_SUCCESS';
export const MATERIAL_MASTER_XREF_SUCCESS = 'MATERIAL_MASTER_XREF_SUCCESS';
export const MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS =
  'MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS';
export const MATERIAL_MASTER_XREF_FORMAT_SUCCESS =
  'MATERIAL_MASTER_XREF_FORMAT_SUCCESS';
export const MATERIAL_MASTER_XREF_BY_ID_SUCCESS =
  'MATERIAL_MASTER_XREF_BY_ID_SUCCESS';
export const MATERIAL_MASTER_XREF_FIELDS_SUCCESS =
  'MATERIAL_MASTER_XREF_FIELDS_SUCCESS';
export const MATERIAL_MASTER_XREF_UPLOAD_SUCCESS =
  'MATERIAL_MASTER_XREF_UPLOAD_SUCCESS';
export const SUPPLIER_MASTER_SUCCESS = 'SUPPLIER_MASTER_SUCCESS';
export const SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS =
  'SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS';
export const IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS =
  'IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS';
export const IMA_CONFIG_CUSTOMER_MASTER_SUCCESS =
  'IMA_CONFIG_CUSTOMER_MASTER_SUCCESS';
export const IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS =
  'IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS';
export const MEMBERSHIP_SUCCESS = 'MEMBERSHIP_SUCCESS';
export const MASTER_REPORT_SUCCESS = 'MASTER_REPORT_SUCCESS';
export const MEMBERSHIP_DETAIL_SUCCESS = 'MEMBERSHIP_DETAIL_SUCCESS';
export const MEMBERSHIP_FORMAT_SUCCESS = 'MEMBERSHIP_FORMAT_SUCCESS';
export const MEMBERSHIP_FORMAT_BY_ID_SUCCESS =
  'MEMBERSHIP_FORMAT_BY_ID_SUCCESS';
export const MEMBERSHIP_FORMAT_FIELDS_SUCCESS =
  'MEMBERSHIP_FORMAT_FIELDS_SUCCESS';
export const MEMBERSHIP_UPLOAD_SUCCESS = 'MEMBERSHIP_UPLOAD_SUCCESS';
export const MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS =
  'MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS';
export const MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS =
  'MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS';
export const MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS =
  'MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS';
export const MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS =
  'MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS';
export const BOM_SUCCESS = 'BOM_SUCCESS';
export const BOM_DETAIL_SUCCESS = 'BOM_DETAIL_SUCCESS';
export const BOM_FORMAT_SUCCESS = 'BOM_FORMAT_SUCCESS';
export const BOM_FORMAT_BY_ID_SUCCESS = 'BOM_FORMAT_BY_ID_SUCCESS';
export const BOM_FORMAT_FIELDS_SUCCESS = 'BOM_FORMAT_FIELDS_SUCCESS';
export const BOM_UPLOAD_SUCCESS = 'BOM_UPLOAD_SUCCESS';
export const SALES_BUNDLE_SUCCESS = 'SALES_BUNDLE_SUCCESS';
export const EMPLOYEE_MASTER_SUCCESS = 'EMPLOYEE_MASTER_SUCCESS';
export const EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS =
  'EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS';
export const INTEGRATION_SETUP_SUCCESS = 'INTEGRATION_SETUP_SUCCESS';
export const TRACK_ACTIVITY_SUCCESS = 'TRACK_ACTIVITY_SUCCESS';
export const FILTER_LIST_SUCCESS = 'FILTER_LIST_SUCCESS';
export const FILTER_LIST_FAIL = 'FILTER_LIST_FAIL';
export const FILTER_BY_ID_LIST_SUCCESS = 'FILTER_BY_ID_LIST_SUCCESS';
export const TERRITORY_SUCCESS = 'TERRITORY_SUCCESS';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const APPROVAL_DESIGNER_GET_SUCCESS = 'APPROVAL_DESIGNER_GET_SUCCESS';
export const APPROVAL_DESIGNER_DETAIL_SUCCESS =
  'APPROVAL_DESIGNER_DETAIL_SUCCESS';
export const CONTRACT_DEFAULT_DESIGNER_SUCCESS =
  'CONTRACT_DEFAULT_DESIGNER_SUCCESS';
export const CONTRACT_DEFAULT_DETAIL_SUCCESS =
  'CONTRACT_DEFAULT_DETAIL_SUCCESS';
export const PAYMENT_DESIGNER_GET_SUCCESS = 'PAYMENT_DESIGNER_GET_SUCCESS';
export const ACCRUAL_DESIGNER_GET_SUCCESS = 'PACCRUAL_DESIGNER_GET_SUCCESS';
export const ACCRUAL_DESIGNER_SINGLE_SUCCESS =
  'ACCRUAL_DESIGNER_SINGLE_SUCCESS';
export const PAYMENT_DESIGNER_SINGLE_SUCCESS =
  'PAYMENT_DESIGNER_SINGLE_SUCCESS';
export const SALES_BASIS_ALL = 'SALES_BASIS_ALL';
export const SALES_BASIS_SINGLE = 'SALES_BASIS_SINGLE';
export const BATCH_JOB_ALL = 'BATCH_JOB_ALL';
export const BATCH_JOB_DETAIL = 'BATCH_JOB_DETAIL';
export const PRICING_BATCH_JOB_ALL = 'PRICING_BATCH_JOB_ALL';
export const PRICING_BATCH_JOB_DETAIL = 'PRICING_BATCH_JOB_DETAIL';
export const EXCEPTION_MANAGEMENT_ALL = 'EXCEPTION_MANAGEMENT_ALL';
export const EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS =
  'EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS';
export const EXCEPTION_MANAGEMENT_SINGLE = 'EXCEPTION_MANAGEMENT_SINGLE';
export const EXCEPTION_MANAGEMENT_TIER_EVALS =
  'EXCEPTION_MANAGEMENT_TIER_EVALS';
export const WORKFLOW_ANCHORS = 'WORKFLOW_ANCHORS';
export const BATCH_JOB_STATUS = 'BATCH_JOB_STATUS';
export const BATCH_JOB_STATUS_SECOND_PAGE = 'BATCH_JOB_STATUS_SECOND_PAGE';
export const BATCH_JOB_STATUS_SINGLE_DATA = 'BATCH_JOB_STATUS_SINGLE_DATA';
export const PRODUCT_GROUP_SUCCESS = 'PRODUCT_GROUP_SUCCESS';
export const PRODUCT_GROUP_SINGLE_SUCCESS = 'PRODUCT_GROUP_SINGLE_SUCCESS';
export const PRODUCT_GROUP_FORMAT_SUCCESS = ' PRODUCT_GROUP_FORMAT_SUCCESS';
export const PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS =
  'PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS';
export const PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS =
  ' PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS';
export const PRODUCT_GROUP_UPLOAD_SUCCESS = 'PRODUCT_GROUP_UPLOAD_SUCCESS';
export const GENERAL_UOM = 'GENERAL_UOM';
export const MATERIAL_UOM_SUCCESS = 'MATERIAL_UOM_SUCCESS';
export const CONVERSION_SUCCESS = 'CONVERSION_SUCCESS';
export const TERRITORY_LIST_SUCCESS = 'TERRITORY_LIST_SUCCESS';
export const CONTRACT_XREF_SUCCESS = 'CONTRACT_XREF_SUCCESS';
export const CONTRACT_XREF_DETAIL_SUCCESS = 'CONTRACT_XREF_DETAIL_SUCCESS';
export const IMA_CONFIG_MATERIAL_MASTER_SUCCESS =
  'IMA_CONFIG_MATERIAL_MASTER_SUCCESS';
export const IMA_CONFIG_SUPPLIER_SUCCESS = 'IMA_CONFIG_SUPPLIER_SUCCESS';
export const SUPPLIER_MASTER_DROPDOWN_SUCCESS =
  'SUPPLIER_MASTER_DROPDOWN_SUCCESS';
export const TERRITORY_SINGLE_SUCCESS = 'TERRITORY_SINGLE_SUCCESS';
export const INTEGRATION_MAPPING_SUCCESS = 'INTEGRATION_MAPPING_SUCCESS';
export const INTEGRATION_QUALIFIERS_SUCCESS = 'INTEGRATION_QUALIFIERS_SUCCESS';
export const NEW_INTEGRATION_MAPPING_SUCCESS = 'NEW_INTEGRATION_MAPPING_SUCCESS';
export const NEW_INTEGRATION_MAPPINGS_DATA_BY_ID = 'NEW_INTEGRATION_MAPPINGS_DATA_BY_ID';
export const INTEGRATION_QUALIFIERS_FUNCTIONALITY_SUCCESS = 'INTEGRATION_QUALIFIERS_FUNCTIONALITY_SUCCESS';
export const INTEGRATION_QUALIFIERS_TABLE_NAME_SUCCESS = 'INTEGRATION_QUALIFIERS_TABLE_NAME_SUCCESS';
export const INTEGRATION_QUALIFIERS_DATA_BY_ID = 'INTEGRATION_QUALIFIERS_DATA_BY_ID';
export const INTEGRATION_LIST_DATA_SUCCESS = 'INTEGRATION_LIST_DATA_SUCCESS';
export const INTEGRATION_LIST_DATA_BY_ID = 'INTEGRATION_LIST_DATA_BY_ID';
export const INTEGRATION_MAPPING_BY_ID = 'INTEGRATION_MAPPING_BY_ID';
export const INTEGRATION_MAPPING_FIELDS = 'INTEGRATION_MAPPING_FIELDS';
export const MAPPING_CREATE_SUCCESS = 'MAPPING_CREATE_SUCCESS';
export const INTEGRATION_LOAD_FREQUENCY = 'INTEGRATION_LOAD_FREQUENCY';
export const INTEGRATION_ACTIVITY = 'INTEGRATION_ACTIVITY';
export const INTEGRATION_DATA_SOURCE = 'INTEGRATION_DATA_SOURCE';
export const IM_HISTORY_SUCCESS = 'IM_HISTORY_SUCCESS';
export const BATCH_INTEGRATION_JOB_ALL = 'BATCH_INTEGRATION_JOB_ALL';
export const BATCH_INTEGRATION_JOB_STATUS = 'BATCH_INTEGRATION_JOB_STATUS';
export const FECTCH_INTEGRATION_SETUP_SUCCESS =
  'FECTCH_INTEGRATION_SETUP_SUCCESS';
export const COT_XREF_SUCCESS = 'COT_XREF_SUCCESS';
export const COT_XREF_FORMAT_SUCCESS = 'COT_XREF_FORMAT_SUCCESS';
export const COT_XREF_UPLOAD_SUCCESS = 'COT_XREF_UPLOAD_SUCCESS';
export const COT_XREF_FIELDS_SUCCESS = 'COT_XREF_FIELDS_SUCCESS';
export const COT_XREF_BY_ID_SUCCESS = 'COT_XREF_BY_ID_SUCCESS';
export const COT_XREF_VIEW_DETAIL_SUCCESS = 'COT_XREF_VIEW_DETAIL_SUCCESS';
export const MEMBERSHIP_HIERARCHY_SUCCESS = 'MEMBERSHIP_HIERARCHY_SUCCESS';
export const MEMBERSHIP_CONTRACT_XREF_SUCCESS =
  'MEMBERSHIP_CONTRACT_XREF_SUCCESS';
export const MEMBERSHIP_CONTRACT_XREF_BY_ID = 'MEMBERSHIP_CONTRACT_XREF_BY_ID';
export const MEMBERSHIP_CONTRACT_GROUP_SUCCESS =
  'MEMBERSHIP_CONTRACT_GROUP_SUCCESS';
export const MEMBERSHIP_CONTRACT_GROUP_BY_ID =
  'MEMBERSHIP_CONTRACT_GROUP_BY_ID';
export const GPO_ADDRESS_SEARCH_SUCCESS = 'GPO_ADDRESS_SEARCH_SUCCESS';
export const TIER_ACTI_ADDRESS_SEARCH_SUCCESS =
  'TIER_ACTI_ADDRESS_SEARCH_SUCCESS';
export const MASS_MEMBERSHIP_UPDATE = 'MASS_MEMBERSHIP_UPDATE';
export const MASS_MEBERSHIP_UPDATE_ACTION = 'MASS_MEBERSHIP_UPDATE_ACTION';
export const WORK_FLOW_ASSIGNMENTS_SUCCESS = 'WORK_FLOW_ASSIGNMENTS_SUCCESS';
export const WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS =
  'WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS';
export const UPLOAD_INTEGRATION_FILE = 'UPLOAD_INTEGRATION_FILE';
export const DEFAULT_DESIGNER_CONFIG_SUCCESS = 'DEFAULT_DESIGNER_CONFIG_SUCCESS';
export const DEFAULT_DESIGNER_SUCCESS = 'DEFAULT_DESIGNER_SUCCESS';
export const DEFAULT_DETAIL_SUCCESS = 'DEFAULT_DETAIL_SUCCESS';