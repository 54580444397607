import {
  QUALIFIER_CONFIG_SUCCESS,
  QUALIFIER_CONFIG_BY_ID,
  PRICE_LIST_FIELDS,
  CATALOG_FILTER_DATA,
  FETCH_START,
  FETCH_END,
  EDI_MAPPINGS_SUCCESS,
  PRICE_TYPE_CONFIG_SUCCESS,
  PRICE_TYPE_CONFIG_BY_ID,
  PRICE_LIST_MASTER_SUCCESS,
  PRICE_LIST_MASTER_BY_ID,
  PRICE_QUALIFIER_NAME_SUCCESS,
  PRICE_NEW_QUALIFIER_NAME_SUCCESS,
  PRICE_TYPE_NAME_SUCCESS,
  PRICE_LIST_DATA_SUCCESS,
  PRICE_LIST_DATA_BY_ID,
  PRICE_MAINTENANCE_FIELDS,
  DISTINCT_PRICE_LIST_DATA,
  PRICE_FORMULA_BY_ID,
  PRICE_FORMULA_SUCCESS,
  LIST_DATA_FORMAT_SUCCESS,
  OFF_INVOICE_CONFIG_SUCCESS,
  CONF_EDI_MAPPINGS_SUCCESS,
  PRICE_CAL_FOR_LIST_SUCCESS,
  PRICE_CAL_FOR_LIST_BY_ID,
  PRICE_CAL_FOR_LIST_BY_NAME,
  PRICE_CAL_FOR_SIMULATION_SUCCESS,
  SIMULATION_DROPDOWN_SUCCESS,
  PRICE_ELASTICITY,
  PRICE_SIM_RESULTS_SUCCESS,
  LIST_DATA_UPLOAD_FAILURE,
  CUSTOMER_MARGIN_SUCCESS,
  PRICE_POLICY_EXCEP,
  DISTINCT_PROFIT_LIST_DATA,
  COMPREHENCIVE_QUOTE_DATA,
  COMPREHENCIVE_QUOTE_DATA_BY_ID,
  PRODUCT_FEATURE_PRICING_SUCCESS,
  PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS,
  PRODUCT_FEATURE_CALCULATE_SUCCESS,
  PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE,
  PRODUCT_FEATURE_FORMAT_SUCCESS,
  PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS,
  PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE,
  PRICE_LIST_DATA_MESSAGELOG,
  PRICE_UNIQUE_SUCCESS,
  PRICE_FILTER_DATA,
  PRICE_CATALOG_SUCCESS,
  PRICE_CHANGE_HISTORY_DATA_SUCCESS,
  PRICE_ADJUST_SUCCESS,
  PRICE_REQUEST_CONFIG_BY_ID,
  PRICE_REQUEST_CONFIG_SUCCESS,
  PRICE_REASON_CODE_CONFIG_SUCCESS,
  PRICE_REASON_CODE_CONFIG_BY_ID,
  PRICE_REQUESTS_LIST_SUCCESS,
  PRICE_REQUESTS_SUCCESS,
  PRICE_REQUESTS_LIST_ID_SUCCESS,
  PRICE_REQUESTS_BY_ID_SUCCESS,
  RESET_PRICE_MAINTENANCE_FIELDS,
  PRICE_CONTRACTS_LIST,
  PRICE_CONTRACT_ID,
  PRICE_CONTRACTS,
} from './ActionTypes';
import secureLocalStorage from 'react-secure-storage';
import axios from '../../../axios.conf';
import { history } from '../../../components/Helpers';
import { toast } from 'react-toastify';
import { ServerError } from '../../../library/constants';
export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const getQualifierConfigSuccess = (data) => {
  return {
    type: QUALIFIER_CONFIG_SUCCESS,
    data,
  };
};

export const getQualifierConfig = (pagination, limit) => {
  return (dispatch) => {
    getQualifierConfigAPI(pagination, limit)
      .then((response) => {
        dispatch(getQualifierConfigSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getQualifierConfigAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'pricing/price-attributes?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const createQualifierConfig = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createQualifierConfigAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete') dispatch(getQualifierConfig(1, 100));
        else history.push('/pricing-qualifier-config');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createQualifierConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing/price-attribute/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('pricing/price-attribute/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('pricing/price-attributes', data, { headers: headers });
  }
  return result;
};

//edit formula
export const getQualifierConfigById = (id) => {
  return (dispatch) => {
    getQualifierConfigByIdAPI(id)
      .then((response) => {
        dispatch(getQualifierConfigSuccess(response.data.response));
        history.push('/pricing-qualifier-config/edit-qualifier-config/' + id);
      })
      .catch((error) => { });
  };
};

const getQualifierConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-attribute/' + id, { headers: headers });
  return result;
};

export const getQualifierConfigByIdSuccess = (data) => {
  return {
    type: QUALIFIER_CONFIG_BY_ID,
    data,
  };
};
export const getPriceListFields = () => {
  return (dispatch) => {
    getPriceListFieldsAPI()
      .then((response) => {
        dispatch(getPriceListFieldsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceListFieldsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('price-list-data-fields', { headers: headers });
  return result;
};
export const getPriceListFieldsSuccess = (data) => {
  return {
    type: PRICE_LIST_FIELDS,
    data,
  };
};

//EDI Mapping fetch data

export const getEDIMappings = (name) => {
  return (dispatch) => {
    getEDIMappingsAPI(name)
      .then((response) => {
        dispatch(getEDIMappingsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getEDIMappingsAPI = (name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('edi-segments?' + 'edi_map=' + name, { headers: headers });

  return result;
};
export const getEDIMappingsSuccess = (data) => {
  return {
    type: EDI_MAPPINGS_SUCCESS,
    data,
  };
};

//price type config

export const getPriceTypeConfig = (pagination, limit, name) => {
  return (dispatch) => {
    getPriceTypeConfigAPI(pagination, limit, name)
      .then((response) => {
        dispatch(getPriceTypeConfigSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceTypeConfigAPI = (pagination, limit, name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (name)
    result = axios.get(
      'pricing/price-types' +
      pagination +
      '&limit=' +
      limit +
      '&price_type_name=' +
      name,
      { headers: headers },
    );
  else result = axios.get('pricing/price-types', { headers: headers });
  return result;
};
export const getPriceTypeConfigSuccess = (data) => {
  return {
    type: PRICE_TYPE_CONFIG_SUCCESS,
    data,
  };
};
export const deletePricingCalcStep = (id, name) => {
  return (dispatch) => {
    deletePricingCalcStepAPI(id)
      .then((response) => {
        dispatch(getPriceCalForListByName(name));
      })
      .catch((error) => { });
  };
};

const deletePricingCalcStepAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };

  var result = axios.delete('pricing-calc-formula-ind/' + id, {
    headers: headers,
  });

  return result;
};

export const createPriceTypeConfig = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceTypeConfigAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/pricing-type-config');
        dispatch(getPriceTypeConfig(1, 0));
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceTypeConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing/price-type/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('pricing/price-type/' + id, { headers: headers });
  } else {
    result = axios.post('pricing/price-types', data, { headers: headers });
  }
  return result;
};

export const getPriceTypeConfigById = (id) => {
  return (dispatch) => {
    getPriceTypeConfigByIdAPI(id)
      .then((response) => {
        dispatch(getPriceTypeConfigByIdSuccess(response.data.response));
        // history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceTypeConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-type/' + id, { headers: headers });
  return result;
};

export const getPriceTypeConfigByIdSuccess = (data) => {
  return {
    type: PRICE_TYPE_CONFIG_BY_ID,
    data,
  };
};

//price list master

export const getPriceListMaster = (pagination, limit, id, usage) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceListMasterAPI(pagination, limit, id, usage)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceListMasterSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceListMasterAPI = (pagination, limit, id, usage) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.get('price-list-master?price_list_id=' + id, {
      headers: headers,
    });
  }
  else if (usage) {
    result = axios.get(
      'config/price-list-masters?page=' + pagination + '&limit=' + limit + '&usage=' + usage +
      '&application_type=' + sessionStorage.getItem('application'),
      { headers: headers },
    );
  }
  else
    result = axios.get(
      'config/price-list-masters?page=' + pagination + '&limit=' + limit,
      { headers: headers },
    );
  return result;
};
export const getPriceListMasterSuccess = (data) => {
  return {
    type: PRICE_LIST_MASTER_SUCCESS,
    data,
  };
};

export const createPriceListMaster = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceListMasterAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete') dispatch(getPriceListMaster(1, 100));
        else history.push('/pricing-list-master');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceListMasterAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('config/price-list-master/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('config/price-list-master/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('config/price-list-masters', data, {
      headers: headers,
    });
  }
  return result;
};

export const getPriceListMasterById = (id) => {
  return (dispatch) => {
    getPriceListMasterByIdAPI(id)
      .then((response) => {
        dispatch(getPriceListMasterByIdSuccess(response.data.response));
        // history.push('/pricing-list-master/edit-list-master/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceListMasterByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('config/price-list-master/' + id, { headers: headers });
  return result;
};

export const getPriceListMasterByIdSuccess = (data) => {
  return {
    type: PRICE_LIST_MASTER_BY_ID,
    data,
  };
};

export const getPriceFieldValues = (qualifierKey) => {
  return (dispatch) => {
    getPriceFieldValuesAPI(qualifierKey)
      .then((response) => {
        if (qualifierKey == 'attribute_key')
          dispatch(getPriceFieldValuesSuccess(response.data.response));
        else dispatch(getPriceTypeFieldValuesSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceFieldValuesAPI = (qualifierKey) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (sessionStorage.getItem('application') == 'Profit Optimization')
    result = axios.get('profit-field-values?name=' + qualifierKey, {
      headers: headers,
    });
  else
    result = axios.get('pricing/price-field-values?name=' + qualifierKey, {
      headers: headers,
    });
  return result;
};
export const getPriceFieldValuesSuccess = (data) => {
  return {
    type: PRICE_QUALIFIER_NAME_SUCCESS,
    data,
  };
};
export const getPriceTypeFieldValuesSuccess = (data) => {
  return {
    type: PRICE_TYPE_NAME_SUCCESS,
    data,
  };
};
//price maintenance
export const getPriceListData = (pagination, limit, priceListId, page) => {
  return (dispatch) => {
    dispatch(fetchStart());

    return getPriceListDataAPI(pagination, limit, priceListId, page)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceListDataSuccess(response.data.response));
        console.log('resulthere', response.data.response);
        return response.data.response; // Return the data so we can use .then() after dispatch
      })
      .catch((error) => {
        console.error('Error fetching price list data:', error);
      });
  };
};

const getPriceListDataAPI = (pagination, limit, priceListId, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'editPriceList') {
    result = axios.get(
      'price-list-data?limit=0',
      { params: priceListId },
      { headers: headers },
    );
  }
  if (page === 'bulkEdit') {
    result = axios.post('pricing/price-list-data', priceListId, {
      headers: headers,
    });
  } else if (page === 'editDriverMaintenance') {
    result = axios.get(
      'profit-analysis-level-data?limit=0',
      { params: priceListId },
      { headers: headers },
    );
  } else {
    if (priceListId) {
      if (page == 'approval')
        result = axios.get(
          'price-list-data?price_list_id=' +
          priceListId.id +
          '&price_type_name=' +
          priceListId.priceName +
          '&calculation_type=' +
          priceListId.calcType +
          '&approval_status=new&page=' +
          pagination +
          '&limit=' +
          limit,
          { headers: headers },
        );
      else
        result = axios.get(
          'price-list-data?price_list_id=' +
          priceListId +
          '&page=' +
          pagination +
          '&limit=' +
          limit,
          { headers: headers },
        );
    } else
      result = axios.get(
        'price-list-data-distinct?page=' + pagination + '&limit=' + limit,
        { headers: headers },
      );
  }
  return result;
};
export const getPriceListDataSuccess = (data) => {
  return {
    type: PRICE_LIST_DATA_SUCCESS,
    data,
  };
};

export const createPriceListData = (data, priceListId, id, type) => {
  return (dispatch) => {
    createPriceListDataAPI(data, priceListId, id, type)
      .then((response) => {
        if (type == 'delete') {
          if (sessionStorage.getItem('application') == 'Pricing') {
            dispatch(getAllDistinctPriceListData(1, 0));
            history.push('/pricing-maintenance');
          }
          if (sessionStorage.getItem('application') == 'Profit Optimization') {
            dispatch(getAllDistinctPriceListData(1, 15));
            history.push('/driver-maintenance');
          }
        } else dispatch(getPriceListData(1, 0, priceListId));
        toast.success(response.data.response?.message);
      })
      .catch((error) => { });
  };
};

const createPriceListDataAPI = (data, priceListId, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    if (sessionStorage.getItem('application') == 'Profit Optimization')
      result = axios.put('profit-analysis-level-data/' + id, data, {
        headers: headers,
      });
    else
      result = axios.put('price-list-data/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    if (id) {
      if (sessionStorage.getItem('application') == 'Profit Optimization')
        result = axios.delete('profit-analysis-level-data/' + id, {
          headers: headers,
        });
      else result = axios.delete('price-list-data/' + id, { headers: headers });
    } else {
      if (sessionStorage.getItem('application') == 'Profit Optimization') {
        result = axios.delete(
          'profit-analysis-level-data?price_list_id=' + priceListId,
          { headers: headers },
        );
      } else {
        result = axios.put('price-type-price-list-data', priceListId, {
          headers: headers,
        });
        //result = axios.delete('price-list-data', priceListId, { headers: headers });
      }
    }
  } else {
    if (sessionStorage.getItem('application') == 'Profit Optimization')
      result = axios.post('profit-analysis-level-data', data, {
        headers: headers,
      });
    else result = axios.post('price-list-data', data, { headers: headers });
  }
  return result;
};

export const createPriceListDataAll = (data, page, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart())
    createPriceListDataAllAPI(data, page, type, id)
      .then((response) => {
        dispatch(fetchEnd())
        // console.log('trese', response);
        if (type === 'delete') {
          toast.success(response.data.response);
          dispatch(pricingRequests());
        }
        else {
          toast.success(response.data.message);
          if (response.data.messageLog) {
            dispatch(getPriceListDataError(response.data));
          }
          else {
            history.push('/pricing-requests');
          }
          /* if (response.data.status == '200' || response.data.status == '201')
                       history.push('/pricing-maintenance')*/
        }

      })
      .catch((error) => {
        dispatch(fetchEnd())
      });
  };
};

const createPriceListDataAllAPI = (data, page, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (sessionStorage.getItem('application') == 'Profit Optimization')
    var result = axios.put('profit-analysis-level-data', data, {
      headers: headers,
    });
  else if (type === 'delete') {
    result = axios.delete(
      'pricing/price-request/' +
      id + '?application_type=Pricing',
      { headers: headers },
    );
  }
  else
    var result = axios.post('pricing/price-request', data, {
      headers: headers,
    });
  return result;
};
export const getPriceListDataError = (data) => {
  return {
    type: PRICE_LIST_DATA_MESSAGELOG,
    data,
  };
};

export const getPriceListDataById = (id) => {
  return (dispatch) => {
    getPriceListDataByIdAPI(id)
      .then((response) => {
        dispatch(getPriceListDataByIdSuccess(response.data.response));
        history.push('/pricing-maintenance/edit-list-data/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceListDataByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('price-list-data/' + id, { headers: headers });
  return result;
};

export const getPriceListDataByIdSuccess = (data) => {
  return {
    type: PRICE_LIST_DATA_BY_ID,
    data,
  };
};

// price maintenance fields

export const getPriceMaintenanceFields = (priceListId) => {
  return (dispatch) => {
    getPriceMaintenanceFieldsAPI(priceListId)
      .then((response) => {
        dispatch(getPriceMaintenanceFieldsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

export const resetPriceMaintenanceFields = () => {
  return {
    type: 'RESET_PRICE_MAINTENANCE_FIELDS',
  };
};

const getPriceMaintenanceFieldsAPI = (priceListId) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (priceListId)
    result = axios.get('price-list-fields?price_list_id=' + priceListId, {
      headers: headers,
    });
  else result = axios.get('price-list-fields', { headers: headers });
  return result;
};

export const getPriceMaintenanceFieldsSuccess = (data) => {
  return {
    type: PRICE_MAINTENANCE_FIELDS,
    data,
  };
};

//price type based

export const getPriceMaintenanceFieldsByPriceType = (data) => {
  return (dispatch) => {
    getPriceMaintenanceFieldsByPriceTypeAPI(data)
      .then((response) => {
        dispatch(
          getPriceMaintenanceFieldsByPriceTypeSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getPriceMaintenanceFieldsByPriceTypeAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('pricing/price-list/fields', data, { headers: headers });
  return result;
};

export const getPriceMaintenanceFieldsByPriceTypeSuccess = (data) => {
  return {
    type: PRICE_MAINTENANCE_FIELDS_BY_PRICE_TYPE,
    data,
  };
};

export const getAllDistinctPriceListData = (pagination, limit, type, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllDistinctPriceListDataAPI(pagination, limit, type, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (sessionStorage.getItem('application') == 'Profit Optimization')
          dispatch(getAllDistinctProfitListDataSuccess(response.data.response));
        else
          dispatch(getAllDistinctPriceListDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getAllDistinctPriceListDataAPI = (pagination, limit, type, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (sessionStorage.getItem('application') == 'Profit Optimization')
    result = axios.get(
      'profit-analysis-level-data-distinct?page=' +
      pagination +
      '&limit=' +
      limit,
      { headers: headers },
    );
  else {
    if (type == 'approval')
      result = axios.get(
        'price-list-data-distinct?page=' +
        pagination +
        '&limit=' +
        limit +
        '&approval_status=new',
        { headers: headers },
      );
    else {
      // result = axios.get('price-list-data-distinct?page=' + pagination + '&limit=' + limit, { headers: headers });
      result = axios.post('pricing/price-list/summary ', data, {
        headers: headers,
      });
    }
  }
  return result;
};
export const getAllDistinctPriceListDataSuccess = (data) => {
  return {
    type: DISTINCT_PRICE_LIST_DATA,
    data,
  };
};
export const getAllDistinctProfitListDataSuccess = (data) => {
  return {
    type: DISTINCT_PROFIT_LIST_DATA,
    data,
  };
};

// pricing Formula
export const getPriceFormula = (pagination, limit) => {
  return (dispatch) => {
    getPriceFormulaAPI(pagination, limit)
      .then((response) => {
        dispatch(getPriceFormulaSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceFormulaAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-formula?page=' + pagination + '&limit=' + limit, {
    headers: headers,
  });
  return result;
};
export const getPriceFormulaSuccess = (data) => {
  return {
    type: PRICE_FORMULA_SUCCESS,
    data,
  };
};

export const createPriceFormula = (data, id, type) => {
  return (dispatch) => {
    createPriceFormulaAPI(data, id, type)
      .then((response) => {
        if (type === 'delete') dispatch(getPriceFormula(1, 100));
        else history.push('/pricing-formula');
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};

const createPriceFormulaAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing-formula/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('pricing-formula/' + id, { headers: headers });
  } else {
    result = axios.post('pricing-formula', data, { headers: headers });
  }
  return result;
};

export const getPriceFormulaById = (id) => {
  return (dispatch) => {
    getPriceFormulaByIdAPI(id)
      .then((response) => {
        dispatch(getPriceFormulaByIdSuccess(response.data.response));
        //history.push('/pricing-type-config/edit-type-config/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceFormulaByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-formula/' + id, { headers: headers });
  return result;
};

export const getPriceFormulaByIdSuccess = (data) => {
  return {
    type: PRICE_FORMULA_BY_ID,
    data,
  };
};
//price calculation

export const runPriceCalculation = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    runPriceCalculationAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const runPriceCalculationAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('pricing-formula-calc-sim', data, { headers: headers });

  return result;
};

// price maintainence upload

export const createPriceMaintenanceUpload = (data, id, type) => {
  return (dispatch) => {
    createPriceMaintenanceUploadAPI(data, id, type)
      .then((response) => {
        history.push('/pricing-maintenance/pricing-maintenance-excel-upload');
        dispatch(fetchPriceListDataUpload());
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};

const createPriceMaintenanceUploadAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=PriceListDataFormat',
      data,
      { headers: headers },
    );
  } else if (type === 'delete') {
    result = axios.delete('upload-format/' + id, { headers: headers });
  } else {
    result = axios.post(
      'upload-format?app-functionality=PriceListDataFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};

export const fetchPriceListDataUpload = () => {
  return (dispatch) => {
    fetchPriceListDataUploadAPI()
      .then((response) => {
        dispatch(fetchPriceListDataUploadSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const fetchPriceListDataUploadAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=PriceListDataFormat', {
    headers: headers,
  });
  return result;
};
export const fetchPriceListDataUploadSuccess = (data) => {
  return {
    type: LIST_DATA_FORMAT_SUCCESS,
    data,
  };
};
export const uploadListDataFile = (id, priceListId, file) => {
  return (dispatch) => {
    dispatch(fetchStart())
    uploadListDataFileApi(id, priceListId, file)
      .then((response) => {
        dispatch(fetchEnd())
        if (response.data.warning_message) {
          toast.success(response.data.warning_message);
          dispatch(ListDataUploadFailure([]));
        }
        else {
          dispatch(ListDataUploadFailure(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd())
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadListDataFileApi = (id, priceListId, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (priceListId)
    result = axios.post(
      'price-list-data-upload?format_id=' +
      id +
      '&price_list_id=' +
      priceListId,
      file,
      { headers: headers },
    );
  else
    result = axios.post('price-list-data-upload?format_id=' + id, file, {
      headers: headers,
    });

  return result;
};
export const ListDataUploadFailure = (data) => {
  return {
    type: LIST_DATA_UPLOAD_FAILURE,
    data,
  };
};
// off invoice config

export const createOffInvoiceConfig = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createOffInvoiceConfigAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/pricing-offinvoice-config');
        dispatch(getOffInvoiceConfig());

        if (type == 'clear') {
          toast.success('Successfully Deleted.');
        } else {
          toast.success(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createOffInvoiceConfigAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'create')
    result = axios.post('pricing-config', data, { headers: headers });
  else result = axios.put('pricing-config/' + id, data, { headers: headers });
  return result;
};

export const getOffInvoiceConfig = () => {
  return (dispatch) => {
    getOffInvoiceConfigAPI()
      .then((response) => {
        //dispatch(getKeyFigureableDataComplete(response.data.response.total_record));
        dispatch(getOffInvoiceConfigSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getOffInvoiceConfigAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/pricing-config', { headers: headers });
  return result;
};

export const getOffInvoiceConfigSuccess = (data) => {
  return {
    type: OFF_INVOICE_CONFIG_SUCCESS,
    data,
  };
};

//Configure EDI Mappings Created Data

export const getConfEdiMappings = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getConfEdiMappingsAPI()
      .then((response) => {
        dispatch(getConfEdiMappingsSuccess(response.data.response));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getConfEdiMappingsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('edi-mappings/distinct', { headers: headers });
  return result;
};
export const getConfEdiMappingsSuccess = (data) => {
  return {
    type: CONF_EDI_MAPPINGS_SUCCESS,
    data,
  };
};

// price calculation formula
export const getPriceCalForList = (pagination, limit, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceCalForListAPI(pagination, limit, type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceCalForListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceCalForListAPI = (pagination, limit, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'Price Calculation Formula') {
    result = axios.get(
      'pricing-calc-formula?formula_type=all&page=' +
      pagination +
      '&limit=' +
      limit,
      { headers: headers },
    );
  } else if (type == 'price catalog' || type == 'Price Optimization') {
    result = axios.get(
      'pricing-calc-formula?formula_type=' +
      type +
      '&page=' +
      pagination +
      '&limit=' +
      limit,
      { headers: headers },
    );
  } else {
    result = axios.get(
      'pricing-calc-formula?formula_type=sale price&page=' +
      pagination +
      '&limit=' +
      limit,
      { headers: headers },
    );
  }
  return result;
};
export const getPriceCalForListSuccess = (data) => {
  return {
    type: PRICE_CAL_FOR_LIST_SUCCESS,
    data,
  };
};

//Create EDI Mappings
export const createEDIMappings = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createEDIMappingsAPI(data, type)
      .then((response) => {
        if (type === 'get') {
          dispatch(fetchEnd());
          dispatch(getConfEdiMappingsSuccess(response.data.response));
        } else {
          dispatch(fetchEnd());
          history.push('/edi-mappings');
          dispatch(getConfEdiMappings());
          toast.success(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createEDIMappingsAPI = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.put('edi-mapping-data', data, { headers: headers });
  } else if (type === 'get') {
    result = axios.post('edi-mapping-data', data, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('edi-mappings', data, { headers: headers });
  } else {
    result = axios.post('edi-mappings', data, { headers: headers });
  }
  return result;
};

//

export const createPriceCalForList = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceCalForListAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete')
          dispatch(getPriceCalForList(1, 100, 'Price Calculation Formula'));
        else history.push('/pricing-calculation-formula');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceCalForListAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing-calc-formula', data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('pricing-calc-formula/' + id, { headers: headers });
  } else {
    result = axios.post('pricing-calc-formula', data, { headers: headers });
  }
  return result;
};

export const getPriceCalForListById = (id) => {
  return (dispatch) => {
    getPriceCalForListByIdAPI(id)
      .then((response) => {
        dispatch(getPriceCalForListByIdSuccess(response.data.response));
        // history.push('/pricing-calculation-formula/edit-formula/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceCalForListByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-calc-formula/' + id, { headers: headers });
  return result;
};

export const getPriceCalForListByIdSuccess = (data) => {
  return {
    type: PRICE_CAL_FOR_LIST_BY_ID,
    data,
  };
};
// get priece cal formula by formula name
export const getPriceCalForListByName = (name) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceCalForListByNameAPI(name)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceCalForListByNameSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceCalForListByNameAPI = (name) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-calc-formula?formula_name=' + name, {
    headers: headers,
  });
  return result;
};

export const getPriceCalForListByNameSuccess = (data) => {
  return {
    type: PRICE_CAL_FOR_LIST_BY_NAME,
    data,
  };
};
//PriceChangeHistory
export const getPriceChangeHistoryData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceChangeHistoryDataAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response.data.response &&
          response.data.response.length > 0
        ) {
          dispatch(fetchEnd());
          dispatch(getPriceChangeHistoryDataSucsses(response.data.response));
          history.push('/price-change-history-results');
        }
        else {
          toast.warning('No records found.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceChangeHistoryDataAPi = (data) => {
  for (let propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;

  result = axios.post(
    '/price-change-history ',
    data,
    { headers: headers },
  );

  return result;
};
export const getPriceChangeHistoryDataSucsses = (data) => {
  return {
    type: PRICE_CHANGE_HISTORY_DATA_SUCCESS,
    data,
  };
};

// run price calculation simulation cpq
export const runPriceCalcForSimulation = (
  data,
  type,
  form,
  simulationNo,
  formula,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceCalcForSimulationAPi(data, type, simulationNo, formula)
      .then((response) => {
        dispatch(fetchEnd());
        if (type == 'price Simulation Historical and Forecast') {
          //dispatch(fetchEnd());
          toast.success(response.data.message);
        } else if (type == 'priceCatalog') {
          //dispatch(fetchEnd());
          toast.success(response.data.message);
          dispatch(priceCatalogSuccess(response.data.response));
          history.push('/price-catalog-report-results');
        } else if (type == 'priceAdjustmentSimulation') {
          toast.success(response.data.message);
          dispatch(priceAdjustmentSuccess(response.data.response));
          history.push({
            pathname: '/price-adjustment-simulation-results',
            formula: data.formula_procedure,
          });
        } else if (type === 'PriceSimulationReport') {
          dispatch(fetchEnd());
          toast.success(response.data.response);
          dispatch(priceCalForSimulationSuccess(response.data.response, null));
          history.push('/price-simulation-report-results');
        } else {
          dispatch(fetchEnd());
          dispatch(priceCalForSimulationSuccess(response.data.response, null));
          if (type == 'details') {
            if (form != 'edit')
              history.push('/price-simulation-detailed-quote/create-quote');
          } else if (type == 'price-policy') {
            history.push('/quote-view/' + data.id);
          } else {
            // toast.success(response.data.message)
            history.push('/price-simulation-results');
          }
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceCalcForSimulationAPi = (data, type, simulationNo, formula) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'PriceSimulationReport') {
    result = axios.post('pricing-calc-sim/price-type-values', data, {
      headers: headers,
    });
  } else if (type === 'priceCatalog') {
    result = axios.post('price-catalog-sim', data, {
      headers: headers,
    });
  } else if (type === 'priceAdjustmentSimulation') {
    result = axios.post('price-optimization', data, {
      headers: headers,
    });
  } else {
    if (simulationNo) {
      if (type == 'price-policy') {
        result = axios.get(
          'pricing-calc-result?simulation_category=' +
          data.simulation_category +
          '&simulation_number=' +
          simulationNo +
          '&formula=' +
          formula +
          '&id=' +
          data.id,
          { headers: headers },
        );
      } else {
        result = axios.get(
          'pricing-calc-result?simulation_category=CPQ&simulation_number=' +
          simulationNo +
          '&formula=' +
          formula,
          { headers: headers },
        );
      }
    } else result = axios.post('pricing-calc-sim', data, { headers: headers });
  }
  return result;
};

export const priceCalForSimulationSuccess = (data, errorList) => {
  return {
    type: PRICE_CAL_FOR_SIMULATION_SUCCESS,
    data,
    errorList,
  };
};
export const priceCatalogSuccess = (data) => {
  return {
    type: PRICE_CATALOG_SUCCESS,
    data,
  };
};
export const priceAdjustmentSuccess = (data) => {
  return {
    type: PRICE_ADJUST_SUCCESS,
    data,
  };
};
export const getSimulationDropdown = (data) => {
  return (dispatch) => {
    getSimulationDropdownAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getSimulationDropdownSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSimulationDropdownAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'pricing-calc-sim/price-type-values?drop_down_value=calculation_simulation_number',
    data,
    { headers: headers },
  );
  return result;
};
export const getSimulationDropdownSuccess = (data) => {
  return {
    type: SIMULATION_DROPDOWN_SUCCESS,
    data,
  };
};
// Price Elasticity API
export const getPriceElasticity = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceElasticityAPI(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.length > 0) {
          dispatch(getPriceElasticitySuccess(response.data.response));
          history.push('/price-elasticity-results');
        } else {
          toast.warn('No records found.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getPriceElasticityAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('price-impact-analysis', data, { headers: headers });
  return result;
};
export const getPriceElasticitySuccess = (data) => {
  return {
    type: PRICE_ELASTICITY,
    data,
  };
};
// customer margin
export const runCustomerMargin = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCustomerMarginAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
        dispatch(customerMarginSuccess(response.data.response, null));
        history.push('/customer-margin-results');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCustomerMarginAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    'pricing-sim-result',
    { params: data },
    { headers: headers },
  );
  return result;
};

export const customerMarginSuccess = (data, errorList) => {
  return {
    type: CUSTOMER_MARGIN_SUCCESS,
    data,
    errorList,
  };
};

// get price simulation results
export const getPriceSimResult = () => {
  return (dispatch) => {
    getPriceSimResultAPI()
      .then((response) => {
        dispatch(getPriceSimResultSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceSimResultAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-sim-result?limit=0', { headers: headers });
  return result;
};
export const getPriceSimResultSuccess = (data) => {
  return {
    type: PRICE_SIM_RESULTS_SUCCESS,
    data,
  };
};
// Market Price
export const runMarketPrice = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMarketPriceAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getMarketPriceAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.post('pricing/market-price/calc-list-data', data, {
    headers: headers,
  });
  return result;
};
// Price Policy Exceptions
export const getPricePolicyExceptionsReport = (pagination, limit) => {
  return (dispatch) => {
    getPricePolicyExceptionsReportAPI(pagination, limit)
      .then((response) => {
        dispatch(getPricePolicyExceptionsReportSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};

const getPricePolicyExceptionsReportAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'pricing-calc-exception?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getPricePolicyExceptionsReportSuccess = (data) => {
  return {
    type: PRICE_POLICY_EXCEP,
    data,
  };
};

export const createComprehensiveQuote = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createComprehensiveQuoteAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/price-simulation-detailed-quote');
        dispatch(getComprehensiveQuote(1, 10));
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createComprehensiveQuoteAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing-comprehensive-quote/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('pricing-comprehensive-quote/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('pricing-comprehensive-quote', data, {
      headers: headers,
    });
  }
  return result;
};

export const getComprehensiveQuote = (pagination, limit) => {
  return (dispatch) => {
    getComprehensiveQuoteAPI(pagination, limit)
      .then((response) => {
        dispatch(getComprehensiveQuoteSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};

const getComprehensiveQuoteAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'pricing-comprehensive-quote?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getComprehensiveQuoteSuccess = (data) => {
  return {
    type: COMPREHENCIVE_QUOTE_DATA,
    data,
  };
};

export const getComprehensiveQuoteById = (id) => {
  return (dispatch) => {
    getComprehensiveQuoteByIdAPI(id)
      .then((response) => {
        dispatch(getComprehensiveQuoteByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};

const getComprehensiveQuoteByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-comprehensive-quote/' + id, { headers: headers });
  return result;
};
export const getComprehensiveQuoteByIdSuccess = (data) => {
  return {
    type: COMPREHENCIVE_QUOTE_DATA_BY_ID,
    data,
  };
};
// product feature pricing
export const getProductFeaturePricingData = (pagination, limit) => {
  return (dispatch) => {
    getProductFeaturePricingDataAPI(pagination, limit).then((response) => {
      dispatch(getProductFeaturePricingDataSuccess(response.data.response));
    });
  };
};
const getProductFeaturePricingDataAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'pricing-product-feature?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getProductFeaturePricingDataSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_PRICING_SUCCESS,
    data,
  };
};
export const createProductFeaturePricing = (data, type, id) => {
  return (dispatch) => {
    createProductFeaturePricingAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getProductFeaturePricingData(1, 10));
        history.push('/product-feature-pricing');
      })
      .catch((error) => { });
  };
};
const createProductFeaturePricingAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('pricing-product-feature/' + id, {
      headers: headers,
    });
  } else if (type === 'edit') {
    result = axios.put('pricing-product-feature/' + id, data, {
      headers: headers,
    });
  } else {
    result = axios.post('pricing-product-feature', data, { headers: headers });
  }
  return result;
};
export const getProductFeaturePricingSingleItem = (id) => {
  return (dispatch) => {
    getProductFeaturePricingSingleItemAPI(id).then((response) => {
      dispatch(
        getProductFeaturePricingSingleItemSuccess(response.data.response),
      );
    });
  };
};
const getProductFeaturePricingSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing-product-feature/' + id, { headers: headers });
  return result;
};
export const getProductFeaturePricingSingleItemSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_PRICING_SINGLE_SUCCESS,
    data,
  };
};
// Product Feature Calculate
export const runProductFeatureCalculate = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getProductFeatureCalculateAPi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.message);
        dispatch(ProductFeatureCalculateSuccess(response.data.response, null));
        history.push('/product-feature-calculate-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getProductFeatureCalculateAPi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.post('pricing-product-feature-calculation', data, {
    headers: headers,
  });
  return result;
};
export const ProductFeatureCalculateSuccess = (data, errorList) => {
  return {
    type: PRODUCT_FEATURE_CALCULATE_SUCCESS,
    data,
    errorList,
  };
};
// proudct feature pricing excel upload
export const createProductFeaturePricingUpload = (data, id, type) => {
  return (dispatch) => {
    createProductFeaturePricingUploadAPI(data, id, type)
      .then((response) => {
        history.push('/product-feature-pricing-excel-upload');
        dispatch(getAllProductFeaturePricingFormat());
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};
const createProductFeaturePricingUploadAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put(
      'upload-format/' + id + '?app-functionality=PricingProductFeatureFormat',
      data,
      { headers: headers },
    );
  } else if (type === 'delete') {
    result = axios.delete('upload-format/' + id, { headers: headers });
  } else {
    result = axios.post(
      'upload-format?app-functionality=PricingProductFeatureFormat',
      data,
      { headers: headers },
    );
  }
  return result;
};
export const getAllProductFeaturePricingFormat = () => {
  return (dispatch) => {
    getAllProductFeaturePricingFormatAPI()
      .then((response) => {
        dispatch(
          getAllProductFeaturePricingFormatSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getAllProductFeaturePricingFormatAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=PricingProductFeatureFormat',
    { headers: headers },
  );
  return result;
};
export const getAllProductFeaturePricingFormatSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_FORMAT_SUCCESS,
    data,
  };
};
export const uploadProductFeatureFile = (id, file) => {
  return (dispatch) => {
    uploadProductFeatureFileApi(id, file)
      .then((response) => {
        dispatch(ProductFeatureUploadFailure(response.data.response));
        if (
          !response.data.response.error_list &&
          response.data.response.error_list == null
        ) {
          toast.success(response.data.response.success_msg);
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadProductFeatureFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const ProductFeatureUploadFailure = (data) => {
  return {
    type: PRODUCT_FEATURE_PRICING_UPLOAD_FAILURE,
    data,
  };
};
export const getProductFeaturePricingFormatFields = () => {
  return (dispatch) => {
    getProductFeaturePricingFormatFieldsApi()
      .then((response) => {
        dispatch(
          getProductFeaturePricingFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getProductFeaturePricingFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=PricingProductFeatureFormat',
    { headers: headers },
  );
  return result;
};

export const getProductFeaturePricingFormatFieldsSuccess = (data) => {
  return {
    type: PRODUCT_FEATURE_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

export const onBulkApprovePriceMaintenance = (data) => {
  return (dispatch) => {
    onBulkApprovePriceMaintenanceApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getAllDistinctPriceListData(1, 0, 'approval'));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const onBulkApprovePriceMaintenanceApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('price-list-bulk-approval', data, { headers: headers });
  return result;
};

export const onApprovePriceMaintenance = (data, priceData) => {
  return (dispatch) => {
    onApprovePriceMaintenanceApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getPriceListData(1, 0, priceData, 'approval'));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const onApprovePriceMaintenanceApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('price-list-approval', data, { headers: headers });
  return result;
};

export const getUniquePriceData = (name) => {
  return (dispatch) => {
    getUniquePriceDataApi()
      .then((response) => {
        //if(name=='price_list_id')
        dispatch(getUniquePriceDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};
const getUniquePriceDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('distinct-price-list-names', { headers: headers });
  return result;
};

export const getUniquePriceDataSuccess = (data) => {
  return {
    type: PRICE_UNIQUE_SUCCESS,
    data,
  };
};

export const getPriceFilterData = (data) => {
  return (dispatch) => {
    getPriceFilterDataAPI(data)
      .then((response) => {
        dispatch(getPriceFilterDataSuccess(response.data.response));
        // history.push('/pricing-maintenance/edit-list-data/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceFilterDataAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('pricing/price-list/filters', data, { headers: headers });
  return result;
};

export const getPriceFilterDataSuccess = (data) => {
  return {
    type: PRICE_FILTER_DATA,
    data,
  };
};

export const getPriceCatalogFilter = (formula) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceCatalogFilterAPI(formula)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceCatalogFilterSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceCatalogFilterAPI = (formula) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('price-catalog-qualifier-names?formula_name=' + formula, {
    headers: headers,
  });
  return result;
};

export const getPriceCatalogFilterSuccess = (data) => {
  return {
    type: CATALOG_FILTER_DATA,
    data,
  };
};

//Price Request config
export const createPriceRequestConfig = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceRequestConfigAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete') dispatch(getPriceRequestConfig());
        else history.push('/pricing-request-config');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceRequestConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing/price-request-type/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('pricing/price-request-type/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('pricing/price-request-type', data, {
      headers: headers,
    });
  }
  return result;
};

export const getPriceRequestConfigById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceRequestConfigByIdAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceRequestConfigByIdSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceRequestConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-request-type/' + id, { headers: headers });
  return result;
};

export const getPriceRequestConfigByIdSuccess = (data) => {
  return {
    type: PRICE_REQUEST_CONFIG_BY_ID,
    data,
  };
};

export const getPriceRequestConfig = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceRequestConfigAPI()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceRequestConfigSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceRequestConfigAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-request-type?page=1&limit=100', {
    headers: headers,
  });
  return result;
};
export const getPriceRequestConfigSuccess = (data) => {
  return {
    type: PRICE_REQUEST_CONFIG_SUCCESS,
    data,
  };
};

//create Pricing Reason Code config
export const createPriceReasonCodeConfig = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceReasonCodeConfigAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete') dispatch(getPriceReasonCodeConfig());
        else history.push('/pricing-reason-code');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceReasonCodeConfigAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('pricing/price-reason-codes/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('pricing/price-reason-codes/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('pricing/price-reason-codes', data, {
      headers: headers,
    });
  }
  return result;
};

//get by id
export const getPriceReasonCodeConfigById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceReasonCodeConfigByIdAPI(id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceReasonCodeConfigByIdSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceReasonCodeConfigByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-reason-codes/' + id, { headers: headers });
  return result;
};

export const getPriceReasonCodeConfigByIdSuccess = (data) => {
  return {
    type: PRICE_REASON_CODE_CONFIG_BY_ID,
    data,
  };
};

// list api
export const getPriceReasonCodeConfig = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPriceReasonCodeConfigAPI()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPriceReasonCodeConfigSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPriceReasonCodeConfigAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('pricing/price-reason-codes?page=1&limit=100', {
    headers: headers,
  });
  return result;
};
export const getPriceReasonCodeConfigSuccess = (data) => {
  return {
    type: PRICE_REASON_CODE_CONFIG_SUCCESS,
    data,
  };
};

//pricing requests list

export const pricingRequestsList = (filters) => {
  return (dispatch) => {
    dispatch(fetchStart());
    pricingRequestsListAPI()
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(pricingRequestsListSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
export const pricingRequestsListSuccess = (data) => {
  return {
    type: PRICE_REQUESTS_LIST_SUCCESS,
    data,
  };
};
const pricingRequestsListAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('pricing/price-requests/' + id, data, {
    headers: headers,
  });
  return result;
};

export const pricingRequestsListByID = (id) => {
  return (dispatch) => {
    getPriceFormulaByIdAPI(id)
      .then((response) => {
        dispatch(pricingRequestsListByIDSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

export const pricingRequestsListByIDSuccess = (data) => {
  return {
    type: PRICE_REQUESTS_LIST_ID_SUCCESS,
    data,
  };
};

//price requests
export const pricingRequests = (filters) => {
  return (dispatch) => {
    dispatch(fetchStart());
    pricingRequestAPI()
      .then((response) => {
        dispatch(fetchEnd());

        console.log('test1', response.data.response);
        dispatch(pricingRequestsSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
export const pricingRequestsSuccess = (data) => {
  return {
    type: PRICE_REQUESTS_SUCCESS,
    data,
  };
};
const pricingRequestAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('pricing/price-requests', { headers: headers });
  return result;
};

export const getPriceRequstByID = (id, type) => {
  return (dispatch) => {
    getPriceRequstByIDAPI(id)
      .then((response) => {
        console.log('here', response.data.response);
        dispatch(getPriceRequstByIDSuccess(response.data.response));

        if (response.data.status == '200' || response.data.status == '201')
          if (type === 'view') history.push('/pricing-requests/view/' + id);
          else history.push('/pricing-requests/edit/' + id);
      })
      .catch((error) => { });
  };
};

const getPriceRequstByIDAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'pricing/price-request/' + id + '?application_type=Pricing',
    { headers: headers },
  );
  return result;
};

export const getPriceRequstByIDSuccess = (data) => {
  return {
    type: PRICE_REQUESTS_BY_ID_SUCCESS,
    data,
  };
};

export const createPriceListValidate = (data) => {
  return (dispatch) => {
    return createPriceListValidateAPI(data)
      .then((response) => {
        toast.success(response.data.response);
        return response;
      })
      .catch((error) => {
        // toast.error('Validation failed. Please check your data.');
        throw error;
      });
  };
};

const createPriceListValidateAPI = (data, priceListId, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (sessionStorage.getItem('application') == 'Profit Optimization')
    var result = axios.put('profit-analysis-level-data', data, {
      headers: headers,
    });
  else
    var result = axios.post('pricing/price-request/validate', data, {
      headers: headers,
    });
  return result;
};

// Pricing Contracts

export const getPricingContractList = (id, type, params) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPricingContractListAPI(id, type, params)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(
          getPricingContractListSuccess(response.data.response, id, type),
        );
        if (type == 'view') {
          history.push('pricing-contract/view/' + id);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPricingContractListAPI = (id, type, params) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'allContracts')
    result = axios.get('/pricing/contracts', { params }, { headers: headers });
  else if (id)
    result = axios.get('/pricing/contracts/' + id, { headers: headers });
  else result = axios.get('/pricing/contract-list', { headers: headers });
  return result;
};
export const getPricingContractListSuccess = (data, id, type) => {
  if (type == 'allContracts')
    return {
      type: PRICE_CONTRACTS,
      data,
    };
  else if (id)
    return {
      type: PRICE_CONTRACT_ID,
      data,
    };
  else
    return {
      type: PRICE_CONTRACTS_LIST,
      data,
    };
};

export const createPriceContracts = (data, id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPriceContractsAPI(data, id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (type === 'delete') {
          dispatch(getPricingContractList());
          toast.success(response.data.response);
        }
        else history.push('/pricing-contract');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPriceContractsAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('/pricing/contracts/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('/pricing/contracts/' + id, { headers: headers });
  } else {
    result = axios.post('/pricing/contracts', data, { headers: headers });
  }
  return result;
};

//pending contract
export const createPendingContract = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    createPendingContractAPI(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/pricing-contract');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const createPendingContractAPI = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('/pricing/contracts/' + id + '/update-request', data, {
    headers: headers,
  });
  return result;
};

//confirm Pending Contract
export const confirmPendingContract = (data, id, status) => {
  return (dispatch) => {
    dispatch(fetchStart());
    confirmPendingContractAPI(data, id, status)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/pricing-contract');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const confirmPendingContractAPI = (data, id, status) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    '/pricing/contracts/' + id + '/update-request/' + status,
    data,
    { headers: headers },
  );
  return result;
};


export const getPriceAttributes = () => {
  return (dispatch) => {
    getPriceAttributesAPI()
      .then((response) => {
        dispatch(getPriceAttributesSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getPriceAttributesAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('mdm/attribute-labels', {
    headers: headers,
  });
  return result;
};

export const getPriceAttributesSuccess = (data) => {
  return {
    type: PRICE_NEW_QUALIFIER_NAME_SUCCESS,
    data,
  };
};