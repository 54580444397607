export const ATTRIBUTE_LIST_SUCCESS = 'ATTRIBUTE_LIST_SUCCESS';
export const ATTRIBUTE_LIST_FAIL = 'ATTRIBUTE_LIST_FAIL';
export const EDIT_ATTRIBUTES_SUCCESS = 'EDIT_ATTRIBUTES_SUCCESS';
export const EDIT_ATTRIBUTES_FAIL = 'EDIT_ATTRIBUTES_FAIL';
export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const ATTRIBUTE_NAME_SUCCESS = 'ATTRIBUTE_NAME_SUCCESS';
export const DROPDOWN_START = 'DROPDOWN_START';
export const DROPDOWN_END = 'DROPDOWN_END';
export const DROPDOWN_DATA_SUCCESS = 'DROPDOWN_DATA_SUCCESS';
export const DROPDOWN_DATA_VALIDATION_SUCCESS =
  'DROPDOWN_DATA_VALIDATION_SUCCESS';
export const DROPDOWN_MASTER_DATA_SUCCESS = 'DROPDOWN_MASTER_DATA_SUCCESS';
export const CREATE_ATTRIBUTE_HIERARCHY_SUCCESS =
  'CREATE_ATTRIBUTE_HIERARCHY_SUCCESS';
export const ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS =
  'ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS';
export const ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS =
  'ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS';
export const DELETE_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS =
  'DELETE_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS';
export const SAVE_EDIT_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS =
  'SAVE_EDIT_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS';
export const UPLOAD_ATTRIBUTE_MANAGEMENT_FILE_SUCCESS =
  'UPLOAD_ATTRIBUTE_MANAGEMENT_FILE_SUCCESS';
export const CREATE_USER_DEFINED_ATTRIBUTE_SUCCESS = 'CREATE_USER_DEFINED_ATTRIBUTE_SUCCESS';
export const CREATE_USER_DEFINED_ATTRIBUTE_FAIL = 'CREATE_USER_DEFINED_ATTRIBUTE_FAIL';
export const UPDATE_USER_DEFINED_ATTRIBUTE_SUCCESS = 'UPDATE_USER_DEFINED_ATTRIBUTE_SUCCESS';
export const UPDATE_USER_DEFINED_ATTRIBUTE_FAIL = 'UPDATE_USER_DEFINED_ATTRIBUTE_FAIL';
export const GET_ATTRIBUTE_VALUES_SUCCESS = 'GET_ATTRIBUTE_VALUES_SUCCESS';
export const GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS = 'GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS';
export const CLEAR_ATTRIBUTE_TYPE_BY_ID = 'CLEAR_ATTRIBUTE_TYPE_BY_ID';
export const UPDATE_ATTRIBUTE_VALUE_SUCCESS = 'UPDATE_ATTRIBUTE_VALUE_SUCCESS';
export const UPDATE_ATTRIBUTE_VALUE_FAIL = 'UPDATE_ATTRIBUTE_VALUE_FAIL';
export const GET_ATTRIBUTE_DROPDOWN_VALUES = 'GET_ATTRIBUTE_DROPDOWN_VALUES';
