import {
  CUSTOMER_MASTER_SUCCESS,
  CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS,
  CUSTOMER_MASTER_XREF_SUCCESS,
  CUSTOMER_MASTER_XREF_DETAIL_SUCCESS,
  CUSTOMER_MASTER_XREF_FORMAT_SUCCESS,
  CUSTOMER_MASTER_XREF_FIELDS_SUCCESS,
  CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS,
  CUSTOMER_MASTER_XREF_BY_ID_SUCCESS,
  MATERIAL_MASTER_SUCCESS,
  MATERIAL_MASTER_VIEW_DETAIL_SUCCESS,
  MATERIAL_MASTER_XREF_SUCCESS,
  MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS,
  MATERIAL_MASTER_XREF_FORMAT_SUCCESS,
  MATERIAL_MASTER_XREF_BY_ID_SUCCESS,
  MATERIAL_MASTER_XREF_FIELDS_SUCCESS,
  MATERIAL_MASTER_XREF_UPLOAD_SUCCESS,
  SUPPLIER_MASTER_SUCCESS,
  SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS,
  IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS,
  IMA_CONFIG_CUSTOMER_MASTER_SUCCESS,
  IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS,
  MEMBERSHIP_SUCCESS,
  MASTER_REPORT_SUCCESS,
  MEMBERSHIP_DETAIL_SUCCESS,
  MEMBERSHIP_FORMAT_SUCCESS,
  MEMBERSHIP_FORMAT_FIELDS_SUCCESS,
  MEMBERSHIP_FORMAT_BY_ID_SUCCESS,
  MEMBERSHIP_UPLOAD_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS,
  BOM_SUCCESS,
  BOM_DETAIL_SUCCESS,
  BOM_FORMAT_SUCCESS,
  SALES_BUNDLE_SUCCESS,
  BOM_FORMAT_FIELDS_SUCCESS,
  BOM_FORMAT_BY_ID_SUCCESS,
  BOM_UPLOAD_SUCCESS,
  EMPLOYEE_MASTER_SUCCESS,
  EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS,
  INTEGRATION_SETUP_SUCCESS,
  TRACK_ACTIVITY_SUCCESS,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_FAIL,
  FILTER_BY_ID_LIST_SUCCESS,
  TERRITORY_SUCCESS,
  FETCH_START,
  FETCH_END,
  APPROVAL_DESIGNER_GET_SUCCESS,
  APPROVAL_DESIGNER_DETAIL_SUCCESS,
  CONTRACT_DEFAULT_DESIGNER_SUCCESS,
  CONTRACT_DEFAULT_DETAIL_SUCCESS,
  PAYMENT_DESIGNER_GET_SUCCESS,
  ACCRUAL_DESIGNER_GET_SUCCESS,
  ACCRUAL_DESIGNER_SINGLE_SUCCESS,
  PAYMENT_DESIGNER_SINGLE_SUCCESS,
  SALES_BASIS_ALL,
  SALES_BASIS_SINGLE,
  BATCH_JOB_ALL,
  BATCH_JOB_DETAIL,
  BATCH_JOB_STATUS,
  BATCH_JOB_STATUS_SECOND_PAGE,
  BATCH_JOB_STATUS_SINGLE_DATA,
  PRICING_BATCH_JOB_ALL,
  PRICING_BATCH_JOB_DETAIL,
  EXCEPTION_MANAGEMENT_ALL,
  EXCEPTION_MANAGEMENT_SINGLE,
  EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS,
  EXCEPTION_MANAGEMENT_TIER_EVALS,
  WORKFLOW_ANCHORS,
  PRODUCT_GROUP_SUCCESS,
  PRODUCT_GROUP_SINGLE_SUCCESS,
  PRODUCT_GROUP_FORMAT_SUCCESS,
  PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS,
  PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS,
  PRODUCT_GROUP_UPLOAD_SUCCESS,
  GENERAL_UOM,
  MATERIAL_UOM_SUCCESS,
  CONVERSION_SUCCESS,
  TERRITORY_LIST_SUCCESS,
  TERRITORY_SINGLE_SUCCESS,
  CONTRACT_XREF_SUCCESS,
  CONTRACT_XREF_DETAIL_SUCCESS,
  IMA_CONFIG_MATERIAL_MASTER_SUCCESS,
  IMA_CONFIG_SUPPLIER_SUCCESS,
  SUPPLIER_MASTER_DROPDOWN_SUCCESS,
  INTEGRATION_MAPPING_SUCCESS,
  INTEGRATION_QUALIFIERS_SUCCESS,
  NEW_INTEGRATION_MAPPING_SUCCESS,
  NEW_INTEGRATION_MAPPINGS_DATA_BY_ID,
  INTEGRATION_QUALIFIERS_FUNCTIONALITY_SUCCESS,
  INTEGRATION_QUALIFIERS_TABLE_NAME_SUCCESS,
  INTEGRATION_QUALIFIERS_DATA_BY_ID,
  INTEGRATION_LIST_DATA_SUCCESS,
  INTEGRATION_LIST_DATA_BY_ID,
  INTEGRATION_MAPPING_BY_ID,
  INTEGRATION_MAPPING_FIELDS,
  MAPPING_CREATE_SUCCESS,
  INTEGRATION_LOAD_FREQUENCY,
  INTEGRATION_ACTIVITY,
  INTEGRATION_DATA_SOURCE,
  IM_HISTORY_SUCCESS,
  BATCH_INTEGRATION_JOB_ALL,
  BATCH_INTEGRATION_JOB_STATUS,
  FECTCH_INTEGRATION_SETUP_SUCCESS,
  COT_XREF_SUCCESS,
  COT_XREF_FORMAT_SUCCESS,
  COT_XREF_UPLOAD_SUCCESS,
  COT_XREF_FIELDS_SUCCESS,
  COT_XREF_BY_ID_SUCCESS,
  COT_XREF_VIEW_DETAIL_SUCCESS,
  MEMBERSHIP_HIERARCHY_SUCCESS,
  MEMBERSHIP_CONTRACT_XREF_SUCCESS,
  MEMBERSHIP_CONTRACT_XREF_BY_ID,
  MEMBERSHIP_CONTRACT_GROUP_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_BY_ID,
  GPO_ADDRESS_SEARCH_SUCCESS,
  TIER_ACTI_ADDRESS_SEARCH_SUCCESS,
  MASS_MEMBERSHIP_UPDATE,
  MASS_MEBERSHIP_UPDATE_ACTION,
  WORK_FLOW_ASSIGNMENTS_SUCCESS,
  WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS,
  UPLOAD_INTEGRATION_FILE,
  DEFAULT_DESIGNER_CONFIG_SUCCESS,
  DEFAULT_DESIGNER_SUCCESS,
  DEFAULT_DETAIL_SUCCESS,
} from '../../../actions/Customers/AddCustomers/ActionTypes';
import { uploadIntegrationFile } from '../../../actions/Customers/AddCustomers/AuthAction';

const initialState = {
  customerMasterData: [],
  customerMasterViewData: [],
  customerMasterXRefData: [],
  customerMasterXRefViewData: [],
  customerMasterXRefFormatData: [],
  customerMasterXRefFormatFieldData: [],
  customerMasterXRefFormatDataById: {},
  customerMasterXRefErrorList: [],
  materialMasterData: [],
  materialMasterViewData: [],
  materialMasterXRefViewData: [],
  materialMasterErrorList: [],
  supplierMasterData: [],
  supplierMasterViewData: [],
  dropdownData: [],
  dropdownDataCustomer: [],
  membershipData: [],
  masterReportData: [],
  membershipDetailsData: [],
  membershipFormatData: [],
  membershipFormatFieldData: [],
  membershipFormatDataById: {},
  membership: [],
  membershipErrorList: [],
  membershipContractGroupFormatData: [],
  membershipContractGroupFormatFieldData: [],
  membershipContractGroupFormatDataById: {},
  membershipContractGroupErrorList: [],
  bomData: [],
  bomDetailsData: [],
  bomFormatData: [],
  bomFormatFieldData: [],
  bompFormatDataById: {},
  bomErrorList: [],
  employeeMasterData: [],
  employeeMasterViewData: [],
  integrationSetupData: [],
  trackActivityData: [],
  filterNameList: [],
  filterNameById: [],
  territoryData: [],
  loading: false,
  approvalDesignerGetAll: [],
  contractDefaultDesignerGetAll: [],
  contractDefaultDetail: [],
  paymentDesignerGetAll: [],
  accrualDesignerGetAll: [],
  accrualDesignerSingle: [],
  paymentDesignerSingle: [],
  salesBasusAll: [],
  batchJobAll: [],
  salesBasisSingle: {},
  batchJobDetail: {},
  exceptionManagementAl: [],
  workflowAnchors: [],
  batchJobStatus: [],
  batchJobStatusSecondPage: [],
  batchJobStatusSingleDataList: [],
  productGroupdata: [],
  productGroupSingleData: [],
  productGroupFormatData: [],
  productGroupFormatFieldData: [],
  productGroupSingleFormatData: [],
  productGroupErrorList: [],
  generalUOMAll: [],
  materialUomData: [],
  currencyData: [],
  territoryList: [],
  territorySingleData: [],
  contractXRefData: [],
  contractXRefViewData: [],
  materialUomData: [],
  currencyData: [],
  dropdownDataMaterial: [],
  dropdownDataSupplier: [],
  supplierMasterDropDown: [],
  integrationMappingData: [],
  integrationQualifiersData: [],
  integrationListData: [],
  newIntegrationMappingData: [],
  newIntegrationMappingDataById: [],
  integrationQualifiersFunctionalityData: [],
  integrationQualifiersTableNameData: [],
  integrationQualifiersDataByID: [],
  integrationListDataByID: [],
  integrationMappingSingleData: [],
  integrationMappingFields: [],
  savedMapData: [],
  frequencyList: [],
  dataSourceList: [],
  activityList: [],
  imHistoryData: {},
  integrationbatchJobAll: [],
  integrationBatchJobStatus: [],
  fetchIntegrationSetupData: [],
  cotXrefData: [],
  cotXrefFormatData: [],
  cotXrefErrorList: [],
  cotXrefFieldsData: [],
  cotXrefSingleData: [],
  cotXrefViewData: [],
  membershipHierarchyData: [],
  pricingBatchJobAll: [],
  pricingBatchJobDetail: [],
  membershipContractXREFData: [],
  membershipContractXREFSingleData: [],
  membershipContractGroupData: [],
  membershipContractGroupSingleData: [],
  salesBundleData: [],
  gpoAddressSearchData: [],
  tierActivationAddressSearchData: [],
  exceptionManagementTierEvals: [],
  membershipMassUpdate: [],
  membershipMassUpdateAction: [],
  exceptionManagementViews: [],
  workFlowAssignmentsGetAll: [],
  workFlowAssignmentsDetail: [],
  uploadIntegrationFile: [],
  defaultDesignerConfig: [],
  defaultDesignerGetAll: [],
  defaultDetail: []
};

const AddCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        loading: true,
      };
    case FETCH_END:
      return {
        loading: false,
      };
    case CUSTOMER_MASTER_SUCCESS:
      return {
        ...state,
        customerMasterData: action.data,
      };
    case CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        customerMasterViewData: action.data,
      };
    case CUSTOMER_MASTER_XREF_SUCCESS:
      return {
        ...state,
        customerMasterXRefData: action.data,
      };
    case CUSTOMER_MASTER_XREF_DETAIL_SUCCESS:
      return {
        ...state,
        customerMasterXRefViewData: action.data,
      };
    case CUSTOMER_MASTER_XREF_FIELDS_SUCCESS:
      return {
        ...state,
        customerMasterXRefFormatFieldData: action.data,
      };
    case CUSTOMER_MASTER_XREF_FORMAT_SUCCESS:
      return {
        ...state,
        customerMasterXRefFormatData: action.data,
      };
    case CUSTOMER_MASTER_XREF_BY_ID_SUCCESS: {
      return {
        ...state,
        customerMasterXRefFormatDataById: action.data,
      };
    }
    case CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS: {
      return {
        ...state,
        customerMasterXRefErrorList: action.data,
      };
    }
    case MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        materialMasterData: action.data,
      };
    case MATERIAL_MASTER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        materialMasterViewData: action.data,
      };
    case MATERIAL_MASTER_XREF_SUCCESS:
      return {
        ...state,
        materialMasterXRefData: action.data,
      };
    case MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        materialMasterXRefViewData: action.data,
      };
    case MATERIAL_MASTER_XREF_FIELDS_SUCCESS:
      return {
        ...state,
        materialMasterXRefFormatFieldData: action.data,
      };
    case MATERIAL_MASTER_XREF_UPLOAD_SUCCESS:
      return {
        ...state,
        materialMasterErrorList: action.data,
      };
    case MATERIAL_MASTER_XREF_FORMAT_SUCCESS:
      return {
        ...state,
        materialMasterXRefFormatData: action.data,
      };
    case MATERIAL_MASTER_XREF_BY_ID_SUCCESS: {
      return {
        ...state,
        materialMasterXRefFormatDataById: action.data,
      };
    }
    case SUPPLIER_MASTER_SUCCESS:
      return {
        ...state,
        supplierMasterData: action.data,
      };
    case SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        supplierMasterViewData: action.data,
      };
    case IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS:
      return {
        ...state,
        dropdownData: action.data,
      };
    case IMA_CONFIG_CUSTOMER_MASTER_SUCCESS:
      return {
        ...state,
        dropdownDataCustomer: action.data,
      };
    case IMA_CONFIG_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        dropdownDataMaterial: action.data,
      };
    case IMA_CONFIG_SUPPLIER_SUCCESS:
      return {
        ...state,
        dropdownDataSupplier: action.data,
      };
    case SUPPLIER_MASTER_DROPDOWN_SUCCESS:
      return {
        ...state,
        supplierMasterDropDown: action.data,
      };
    case IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS:
      return {
        ...state,
        dropdownDataMaterialXRef: action.data,
      };
    case MEMBERSHIP_SUCCESS:
      return {
        ...state,
        membershipData: action.data,
      };
    case MASTER_REPORT_SUCCESS:
      return {
        ...state,
        masterReportData: action.data,
      };
    case MEMBERSHIP_DETAIL_SUCCESS:
      return {
        ...state,
        membershipDetailsData: action.data,
      };
    case MEMBERSHIP_FORMAT_SUCCESS:
      return {
        ...state,
        membershipFormatData: action.data,
      };
    case MEMBERSHIP_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        membershipFormatFieldData: action.data,
      };
    case MEMBERSHIP_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        membershipFormatDataById: action.data,
      };
    case MEMBERSHIP_UPLOAD_SUCCESS:
      return {
        ...state,
        membershipErrorList: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS:
      return {
        ...state,
        membershipContractGroupFormatData: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        membershipContractGroupFormatFieldData: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        membershipContractGroupFormatDataById: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS:
      return {
        ...state,
        membershipContractGroupErrorList: action.data,
      };
    case BOM_SUCCESS:
      return {
        ...state,
        bomData: action.data,
      };
    case BOM_DETAIL_SUCCESS:
      return {
        ...state,
        bomDetailsData: action.data,
      };
    case BOM_FORMAT_SUCCESS:
      return {
        ...state,
        bomFormatData: action.data,
      };
    case BOM_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        bomFormatFieldData: action.data,
      };
    case BOM_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        bompFormatDataById: action.data,
      };
    case BOM_UPLOAD_SUCCESS:
      return {
        ...state,
        bomErrorList: action.data,
      };
    case EMPLOYEE_MASTER_SUCCESS:
      return {
        ...state,
        employeeMasterData: action.data,
      };
    case EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        employeeMasterViewData: action.data,
      };
    case INTEGRATION_SETUP_SUCCESS:
      return {
        ...state,
        integrationSetupData: action.data,
      };
    case TRACK_ACTIVITY_SUCCESS:
      return {
        ...state,
        trackActivityData: action.data,
      };
    case FILTER_LIST_SUCCESS: {
      return {
        ...state,
        filterNameList: action.data,
      };
    }
    case FILTER_LIST_FAIL: {
      return {
        ...state,
      };
    }
    case FILTER_BY_ID_LIST_SUCCESS: {
      return {
        ...state,
        filterNameById: action.data,
      };
    }
    case TERRITORY_SUCCESS:
      return {
        ...state,
        territoryData: action.data,
      };
    case APPROVAL_DESIGNER_GET_SUCCESS:
      return {
        ...state,
        approvalDesignerGetAll: action.data,
      };
    case APPROVAL_DESIGNER_DETAIL_SUCCESS:
      return {
        ...state,
        approvalDesignerGetDetail: action.data,
      };
    case CONTRACT_DEFAULT_DESIGNER_SUCCESS:
      return {
        ...state,
        contractDefaultDesignerGetAll: action.data,
      };
    case CONTRACT_DEFAULT_DETAIL_SUCCESS:
      return {
        ...state,
        contractDefaultDetail: action.data,
      };
    case PAYMENT_DESIGNER_GET_SUCCESS:
      return {
        ...state,
        paymentDesignerGetAll: action.data,
      };
    case ACCRUAL_DESIGNER_GET_SUCCESS:
      return {
        ...state,
        accrualDesignerGetAll: action.data,
      };
    case ACCRUAL_DESIGNER_SINGLE_SUCCESS:
      return {
        ...state,
        accrualDesignerSingle: action.data,
      };
    case PAYMENT_DESIGNER_SINGLE_SUCCESS:
      return {
        ...state,
        paymentDesignerSingle: action.data,
      };
    case SALES_BASIS_ALL:
      return {
        ...state,
        salesBasisAll: action.data,
      };
    case SALES_BASIS_SINGLE:
      return {
        ...state,
        salesBasisSingle: action.data,
      };
    case BATCH_JOB_ALL:
      return {
        ...state,
        batchJobAll: action.data,
      };
    case BATCH_JOB_DETAIL:
      return {
        ...state,
        batchJobDetail: action.data,
      };
    case PRICING_BATCH_JOB_ALL:
      return {
        ...state,
        pricingBatchJobAll: action.data,
      };
    case PRICING_BATCH_JOB_DETAIL:
      return {
        ...state,
        pricingBatchJobDetail: action.data,
      };
    case EXCEPTION_MANAGEMENT_ALL:
      return {
        ...state,
        exceptionManagementAll: action.data,
      };
    case EXCEPTION_MANAGEMENT_SINGLE:
      return {
        ...state,
        exceptionManagementSingle: action.data,
      };
    case EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        exceptionManagementViews: action.data,
      };
    case EXCEPTION_MANAGEMENT_TIER_EVALS:
      return {
        ...state,
        exceptionManagementTierEvals: action.data,
      };
    case WORKFLOW_ANCHORS:
      return {
        ...state,
        workflowAnchors: action.data,
      };
    case BATCH_JOB_STATUS:
      return {
        ...state,
        batchJobStatus: action.data,
      };
    case BATCH_JOB_STATUS_SECOND_PAGE:
      return {
        ...state,
        batchJobStatusSecondPage: action.data,
      };
    case BATCH_JOB_STATUS_SINGLE_DATA:
      return {
        ...state,
        batchJobStatusSingleDataList: action.data,
      };
    case PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroupData: action.data,
      };
    case PRODUCT_GROUP_SINGLE_SUCCESS:
      return {
        ...state,
        productGroupSingleData: action.data,
      };
    case PRODUCT_GROUP_FORMAT_SUCCESS:
      return {
        ...state,
        productGroupFormatData: action.data,
      };
    case PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS:
      return {
        ...state,
        productGroupFormatFieldData: action.data,
      };
    case PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS:
      return {
        ...state,
        productGroupSingleFormatData: action.data,
      };
    case PRODUCT_GROUP_UPLOAD_SUCCESS:
      return {
        ...state,
        productGroupErrorList: action.data,
      };
    case GENERAL_UOM:
      return {
        ...state,
        generalUOMAll: action.data,
      };
    case MATERIAL_UOM_SUCCESS:
      return {
        ...state,
        materialUomData: action.data,
      };
    case CONVERSION_SUCCESS:
      return {
        ...state,
        currencyData: action.data,
      };
    case TERRITORY_LIST_SUCCESS:
      return {
        ...state,
        territoryList: action.data,
      };
    case TERRITORY_SINGLE_SUCCESS:
      return {
        ...state,
        territorySingleData: action.data,
      };
    case CONTRACT_XREF_SUCCESS:
      return {
        ...state,
        contractXRefData: action.data,
      };
    case CONTRACT_XREF_DETAIL_SUCCESS:
      return {
        ...state,
        contractXRefViewData: action.data,
      };
    case INTEGRATION_MAPPING_SUCCESS:
      return {
        ...state,
        integrationMappingData: action.data,
      };
    case INTEGRATION_QUALIFIERS_SUCCESS:
      return {
        ...state,
        integrationQualifiersData: action.data,
      };
    case INTEGRATION_LIST_DATA_SUCCESS:
      return {
        ...state,
        integrationListData: action.data,
      };
    case NEW_INTEGRATION_MAPPING_SUCCESS:
      return {
        ...state,
        newIntegrationMappingData: action.data,
      };
    case NEW_INTEGRATION_MAPPINGS_DATA_BY_ID:
      return {
        ...state,
        newIntegrationMappingDataById: action.data,
      };
    case INTEGRATION_QUALIFIERS_FUNCTIONALITY_SUCCESS:
      return {
        ...state,
        integrationQualifiersFunctionalityData: action.data,
      };
    case INTEGRATION_QUALIFIERS_TABLE_NAME_SUCCESS:
      return {
        ...state,
        integrationQualifiersTableNameData: action.data,
      };
    case INTEGRATION_QUALIFIERS_DATA_BY_ID:
      return {
        ...state,
        integrationQualifiersDataByID: action.data,
      };
    case INTEGRATION_LIST_DATA_BY_ID:
      return {
        ...state,
        integrationListDataByID: action.data,
      };
    case INTEGRATION_MAPPING_BY_ID:
      return {
        ...state,
        integrationMappingSingleData: action.data,
      };
    case INTEGRATION_MAPPING_FIELDS:
      return {
        ...state,
        integrationMappingFields: action.data,
      };
    case MAPPING_CREATE_SUCCESS:
      return {
        ...state,
        savedMapData: action.data,
      };
    case INTEGRATION_LOAD_FREQUENCY:
      return {
        ...state,
        frequencyList: action.data,
      };
    case INTEGRATION_DATA_SOURCE:
      return {
        ...state,
        dataSourceList: action.data,
      };
    case INTEGRATION_ACTIVITY:
      return {
        ...state,
        activityList: action.data,
      };
    case IM_HISTORY_SUCCESS:
      return {
        ...state,
        imHistoryData: action.data,
      };
    case BATCH_INTEGRATION_JOB_ALL:
      return {
        ...state,
        integrationbatchJobAll: action.data,
      };
    case BATCH_INTEGRATION_JOB_STATUS:
      return {
        ...state,
        integrationBatchJobStatus: action.data,
      };
    case FECTCH_INTEGRATION_SETUP_SUCCESS:
      return {
        ...state,
        fetchIntegrationSetupData: action.data,
      };
    case COT_XREF_SUCCESS:
      return {
        ...state,
        cotXrefData: action.data,
      };
    case COT_XREF_FORMAT_SUCCESS:
      return {
        ...state,
        cotXrefFormatData: action.data,
      };
    case COT_XREF_UPLOAD_SUCCESS:
      return {
        ...state,
        cotXrefErrorList: action.data,
      };
    case COT_XREF_FIELDS_SUCCESS:
      return {
        ...state,
        cotXrefFieldsData: action.data,
      };
    case COT_XREF_BY_ID_SUCCESS:
      return {
        ...state,
        cotXrefSingleData: action.data,
      };
    case COT_XREF_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        cotXrefViewData: action.data,
      };
    case MEMBERSHIP_HIERARCHY_SUCCESS:
      return {
        ...state,
        membershipHierarchyData: action.data,
      };
    case SALES_BUNDLE_SUCCESS:
      return {
        ...state,
        salesBundleData: action.data,
      };
    case MEMBERSHIP_CONTRACT_XREF_SUCCESS:
      return {
        ...state,
        membershipContractXREFData: action.data,
      };
    case MEMBERSHIP_CONTRACT_XREF_BY_ID:
      return {
        ...state,
        membershipContractXREFSingleData: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_SUCCESS:
      return {
        ...state,
        membershipContractGroupData: action.data,
      };
    case MEMBERSHIP_CONTRACT_GROUP_BY_ID:
      return {
        ...state,
        membershipContractGroupSingleData: action.data,
      };
    case GPO_ADDRESS_SEARCH_SUCCESS:
      return {
        ...state,
        gpoAddressSearchData: action.data,
      };
    case TIER_ACTI_ADDRESS_SEARCH_SUCCESS:
      return {
        ...state,
        tierActivationAddressSearchData: action.data,
      };
    case MASS_MEMBERSHIP_UPDATE:
      return {
        ...state,
        membershipMassUpdate: action.data,
      };
    case MASS_MEBERSHIP_UPDATE_ACTION:
      return {
        ...state,
        membershipMassUpdateAction: action.data,
      };
    case WORK_FLOW_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        workFlowAssignmentsGetAll: action.data,
      };
    case WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS:
      return {
        ...state,
        workFlowAssignmentsDetail: action.data,
      };
    case DEFAULT_DESIGNER_CONFIG_SUCCESS:
      return {
        ...state,
        defaultDesignerConfig: action.data,
      };
    case DEFAULT_DESIGNER_SUCCESS:
      return {
        ...state,
        defaultDesignerGetAll: action.data,
      };
    case DEFAULT_DETAIL_SUCCESS:
      return {
        ...state,
        defaultDetail: action.data,
      };
    default:
      return state;
  }
};

export default AddCustomerReducer;
