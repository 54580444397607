import {
  ATTRIBUTE_LIST_SUCCESS,
  ATTRIBUTE_LIST_FAIL,
  FETCH_START,
  FETCH_END,
  EDIT_ATTRIBUTES_SUCCESS,
  EDIT_ATTRIBUTES_FAIL,
  ATTRIBUTE_NAME_SUCCESS,
  DROPDOWN_START,
  DROPDOWN_END,
  DROPDOWN_DATA_SUCCESS,
  DROPDOWN_MASTER_DATA_SUCCESS,
  DROPDOWN_DATA_VALIDATION_SUCCESS,
  ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS,
  ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS,
  DELETE_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
  SAVE_EDIT_ATTRIBUTE_MANAGEMENT_DATA_SUCCESS,
  UPLOAD_ATTRIBUTE_MANAGEMENT_FILE_SUCCESS,
  GET_ATTRIBUTE_VALUES_SUCCESS,
  GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS,
  CLEAR_ATTRIBUTE_TYPE_BY_ID,
  GET_ATTRIBUTE_DROPDOWN_VALUES
} from '../../actions/Attributes/ActionTypes';

const initialState = {
  loading: false,
  AttributeData: [],
  AttributeDataSingle: [],
  editAttributeData: null,
  attributeNameList: [],
  dropdownLoading: false,
  dropdownData: [],
  dropdownDataValidation: [],
  dropdownMasterData: [],
  attributeManagementData: [],
  attributeManagementDataSingle: [],
  attributeManagementEditData: null,
  attributeManagementLoading: false,
  attributeValues: [],
  attributeTypeById: null,
  totalCount: 0,
  attributeDropdownData: []
};

const AttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTRIBUTE_LIST_SUCCESS:
      // console.log('Reducer received data:', action.data);
      return {
        ...state,
        AttributeData: action.data,
        loading: false,
      };
    case ATTRIBUTE_LIST_SINGLE_DATA_SUCCESS:
      // console.log('Reducer received data:', action.data);
      return {
        ...state,
        AttributeDataSingle: action.data,
        loading: false,
      };
    case ATTRIBUTE_NAME_SUCCESS:
      // console.log('Reducer received data:', action.data);
      return {
        ...state,
        attributeNameList: action.data,
        loading: false,
      };
    case ATTRIBUTE_LIST_FAIL:
      // console.log('Reducer received error:', action.error);
      return {
        ...state,
        loading: false,
      };
    case EDIT_ATTRIBUTES_SUCCESS:
      // console.log('Reducer received data:', action.data);
      return {
        ...state,
        editAttributeData: action.data,
        loading: false,
      };
    case EDIT_ATTRIBUTES_FAIL:
      // console.log('Reducer received error:', action.error);
      return {
        ...state,
        loading: false,
      };
    case FETCH_START:
      // console.log('Reducer started fetching data');
      return {
        ...state,
        loading: true,
      };
    case FETCH_END:
      // console.log('Reducer finished fetching data');
      return {
        ...state,
        loading: false,
      };
    case DROPDOWN_START:
      // console.log('Reducer started fetching dropdown data');
      return {
        ...state,
        dropdownLoading: true,
      };
    case DROPDOWN_END:
      // console.log('Reducer finished fetching dropdown data');
      return {
        ...state,
        dropdownLoading: false,
      };
    case DROPDOWN_MASTER_DATA_SUCCESS:
      // console.log('Reducer received dropdown master data:', action.data);
      return {
        ...state,
        dropdownMasterData: action.data,
        dropdownLoading: false,
      };
    case DROPDOWN_DATA_SUCCESS:
      // console.log('Reducer received dropdown data:', action.data);
      return {
        ...state,
        dropdownData: action.data,
        dropdownLoading: false,
      };
    case DROPDOWN_DATA_VALIDATION_SUCCESS:
      // console.log('Reducer received dropdown data validation:', action.data);
      return {
        ...state,
        dropdownDataValidation: action.data,
        loading: false,
      };
    case ATTRIBUTE_MANAGEMENT_LIST_DATA_SUCCESS:
      // console.log('Reducer received data:', action.data);
      return {
        ...state,
        attributeManagementData: action.data,
        totalCount: action.totalCount || 0,
        attributeManagementLoading: false,
      };

    case GET_ATTRIBUTE_VALUES_SUCCESS:
      // console.log('Reducer received attribute values:', action.data);
      return {
        ...state,
        attributeValues: action.data.records || [],
        loading: false,
      };

    case GET_ATTRIBUTE_TYPE_BY_ID_SUCCESS:
      // console.log('Reducer received attribute type by id:', action.data);
      return {
        ...state,
        attributeTypeById: action.data,
        loading: false,
      };

    case CLEAR_ATTRIBUTE_TYPE_BY_ID:
      // console.log('Reducer cleared attribute type by id');
      return {
        ...state,
        attributeTypeById: null,
      };
    case GET_ATTRIBUTE_DROPDOWN_VALUES:
      return {
        ...state,
        attributeDropdownData: action.data,
      };
    default:
      // console.log('Reducer received unknown action:', action.type);
      return state;
  }
};

export default AttributesReducer;
